import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'
import {listQuestions as list, updateQuestions} from '../../../../redux/calculator'
import { useForm } from "react-hook-form";
import { Alert } from 'antd';
import Block from "./Components/Block";
import { PageHeader, Spin } from "antd";

const routes = [
  {
    path: "index",
    breadcrumbName: "Calculateur",
  },
  {
    path: "index",
    breadcrumbName: "Les questions",
  },
];

function QuestionsPage() {
  const dispatch = useDispatch()
  const [openInfo, setOpenInfo] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });

  const {loadingListQuestions, listQuestions} = useSelector((state: any)=> state.calculator)

  useEffect(()=> {
    dispatch(list())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReset = () => { reset() ; setOpenInfo(true); return dispatch(list())}

  const handleChange = (value: any) => {
    dispatch(updateQuestions({value: value, callback: ()=>setOpenSuccess(true)}))
  }

  return (
    <>
    {!loadingListQuestions && listQuestions ?
    <>
     {openInfo ? <Alert message="Les données précédemment enregistrées ont été définies" type="info" showIcon closable banner /> : null}
     {openSuccess ? <Alert message="Les valeurs ont été mises à jour avec succès" type="success" showIcon closable banner /> : null}
       <PageHeader
        title={"Les questions"}
        ghost={false}
        breadcrumb={{ routes }}
        style={{ backgroundColor: "#E5E5E5" }}
      />
      <div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
        <div style={{ backgroundColor: "white", height: "100%" }}>
          <Block
            first
            value={listQuestions[0].gender}
            name={"gender"}
            title={"Vous êtes"}
            data={["Femme", "Homme"]}
            control={control}
          />
          <Block
            value={listQuestions[0].activity}
            control={control}
            name={"activity"}
            title={"Activité physique"}
            data={[
              "Sédentaire",
              "Activité physique modérée",
              "Activité physique intense",
            ]}
          />
          <Block
           value={listQuestions[0].seasonProducts}
            control={control}
            name={"seasonProducts"}
            title={"Consommez vous des produits de saison ?"}
            data={["Non", "Oui"]}
          />
          <Block
           value={listQuestions[0].biologyProducts}
            control={control}
            name={"biologyProducts"}
            title={
              "Consommez vous des fruits/légumes/céréales issus de l'agriculture biologique ?"
            }
            data={["Non", "Oui"]}
          />
          <Block
           value={listQuestions[0].biologyMilk}
            control={control}
            name={"biologyMilk"}
            title={
              "Consommez vous de la viande et des produits laitiers issus de l'agriculture biologique ?"
            }
            data={["Non", "Oui"]}
          />
          <Block
           value={listQuestions[0].localeProducts}
            control={control}
            name={"localeProducts"}
            title={"Faites-vous attention à acheter des produits locaux ?"}
            data={["Non", "Oui"]}
          />
          <Block
           value={listQuestions[0].productsWaste}
            control={control}
            name={"productsWaste"}
            title={"Ferez-vous attention au gaspillage alimentaire ?"}
            data={["Non", "Oui"]}
          />
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-end",
              paddingBottom: "34px",
            }}
          >
            <div style={{ paddingRight: "12.5px" }}>
              <div
                onClick={() => handleSubmit(handleChange)()}
                style={{
                  backgroundColor: "#089EB7",
                  cursor: "pointer",
                  width: "89.35px",
                  height: "36.9px",
                  lineHeight: "36.9px",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                  }}
                >
                  Valider
                </span>
              </div>
            </div>
            <div style={{ paddingRight: "107px" }} onClick={()=>handleReset()}>
              <div
                style={{
                  backgroundColor: "white",
                  cursor: "pointer",
                  width: "89.35px",
                  height: "36.9px",
                  lineHeight: "36.9px",
                  border: "1px solid #D9D9D9",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              >
                <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
                  Anuler
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "76px" }}></div>
    </> : <Spin/>}
    </>
  );
}

export default QuestionsPage;

import React, { useState } from 'react';
import { Form, Input, Button, Typography, Alert } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import logo from '../../assets/img/logo-waterfamily.png';
import background from '../../assets/img/background.png';
import authServices from '../../redux/auth/services';

const { Text } = Typography;

function RecoverRequest() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const onFinish = async ({ email }: { email: string }) => {
    try {
      setError('');
      setLoading(true);
      setUserEmail(email)
      await authServices.requestRecovery(email);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.status === 404) setError('Utilisateur non trouvé')
      else setError(err?.response?.data?.title);
      setLoading(false);
    }
  };

  const onResend = async () => {
    try {
      setError('');
      setLoading(true);
      await authServices.requestRecovery(userEmail);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.status === 404) setError('Utilisateur non trouvé')
      else setError(err?.response?.data?.title);
      setLoading(false);
    }
  }

  if (success) {
    return (
      <div style={{
        height: "100vh", maxWidth: "1400px", margin: "0px auto", backgroundPosition: "center"
        , backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"
      }}>

        <div className={styles.login_form_container}>
          <img src={logo} alt="Logo" className={styles.login_form_logo} />
          <div className={styles.check_container}>
            <Text>10 minutes se sont écoulées et vous n'avez pas reçu le message?</Text>
            <Alert message="Email envoyé" type="success" showIcon />
          </div>
          <Button onClick={() => onResend()} style={{ border: 'none', boxShadow: 'none' }}>
            <Text style={{ textDecoration: 'underline', color: '#089EB7' }}>Renvoyer</Text>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div style={{
      height: "100vh", maxWidth: "1400px", margin: "0px auto", backgroundPosition: "center"
      , backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"
    }}>

      <div className={styles.login_form_container}>
        <img src={logo} alt="Logo" className={styles.login_form_logo} />
        <div className={styles.text}>
          <Text>Saisissez votre adresse e-mail pour recevoir un e-mail de récupération de mot de passe.</Text>
        </div>
        <Form name="recovery_request" onFinish={onFinish}>
          <Form.Item
            className={styles.space}
            label=""
            name="email"
            rules={[
              { required: true, message: 'Veuillez entrer votre email' },
              { type: 'email', message: 'Entrer une adresse email valide' },
            ]}
            validateStatus={error && 'error'}
            help={error || null}
          >
            <Input
              prefix={
                <UserOutlined
                  className="site-form-item-icon"
                  style={{ color: '#089EB7' }}
                />
              }
              placeholder="Courrier électronique"
              disabled={loading}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.login_form_submit}
              disabled={loading}
              block
            >
              Montez
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default RecoverRequest;

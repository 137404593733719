import React, {useEffect, useState} from "react";
import {listCalnut as list} from '../../../../redux/calculator'
import {useDispatch, useSelector} from 'react-redux'
import {data,dataExpandable} from './conf'

import TableCustom from '../../../../components/Table'

function AgribalysePage() {
  const dispatch = useDispatch()
  const {loadingListCalnut, listCalnut } = useSelector((state: { calculator: any; })=> state.calculator)

  useEffect(() => {
    dispatch(list());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableCustom
    title={"CALNUT"}
    header={'CALNUT'}
    isExpandable
    loading={loadingListCalnut}
    data={listCalnut}
    pageSize={8}
    isEdit
    columns={data}
    columnsExp = {listCalnut}
    expandableColumns={dataExpandable}
    />
  )
}

export default AgribalysePage;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { PageHeader, Row, Col, Select } from 'antd'
import { Bar } from 'react-chartjs-2';
import { fetchStatisticsByNutritionalImpact } from "../../../redux/statistics/index";
import moment from "moment";

function StatistiquesByAge() {
    const {Option} = Select
    const [dates, setDates] = useState({
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().endOf('year').format('YYYY-MM-DD')
    })
    const { statisticsByEmpreinteNutritionnel, fetchStatisticsLoading } = useSelector((state: { statistics: any; }) => state.statistics)
    const dispatch = useDispatch()
    const [mens, setMens]: any = useState({})
    const [girls, setGirls]: any = useState({})
    const [years, setYears]: any = useState([])

    useEffect(() => {
        dispatch(fetchStatisticsByNutritionalImpact(dates))
        let currentYear = moment().year()
        let temp = []
        for (let index = currentYear-3; index <= currentYear+3; index++) {
            temp.push(index)
        }
        setYears(temp)
    }, [])
    useEffect(() => {
        if (statisticsByEmpreinteNutritionnel != null) {
            setMens(statisticsByEmpreinteNutritionnel.mens)
            setGirls(statisticsByEmpreinteNutritionnel.girls)
        }
    }, [fetchStatisticsLoading])
    const routes = [
        {
            path: "index",
            breadcrumbName: "Statistiques",
        },
        {
            path: "first",
            breadcrumbName: "Statistics",
        },
    ];
    const data = {
        labels: ['1', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
        datasets: [
            {
                label: 'Homme',
                data: [mens['0'], mens['10'], mens['20'], mens['30'], mens['40'], mens['50'], mens['60'], mens['70'], mens['80'], mens['90']],
                backgroundColor: 'rgba(26, 51, 86, 1)',
            },
            {
                label: 'Femme',
                data: [girls['0'], girls['10'], girls['20'], girls['30'], girls['40'], girls['50'], girls['60'], girls['70'], girls['80'], girls['90']],
                backgroundColor: 'rgba(8, 158, 183, 1)',
            },
        ],
    };

    const options: any = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    const handleDates = (value: any) => {
        setDates({
            startDate: `${value}-01-01`,
            endDate: `${value}-12-31`
        })
    }

    useEffect(() => {
        dispatch(fetchStatisticsByNutritionalImpact(dates))
    }, [dates])

    return (
        <>
            <div>
                <Row style={{ backgroundColor: 'white', padding: '3% 0% 3% 10%' }}>
                    <Col sm={24}>
                        <h2>Empreinte Nutritionnel</h2>
                    </Col>
                    <Col sm={24}>
                        <Select defaultValue={moment().year()} onChange={handleDates}>
                            {years.map((e: any) => {
                                return (
                                    <Option value={e}>{e}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row >
                    <Col sm={24}>
                        <div style={{ backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Bar style={{ height: '100%', width: '100%' }} data={data} options={options} />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default StatistiquesByAge;
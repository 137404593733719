import React, {useEffect, useState} from 'react';
import {
  PageHeader,
  Table,
} from 'antd';
import AdminModalForm from '../../../components/AdminModalForm';
import AdminModalEditForm from '../../../components/AdminModalEditForm';
import AdminModalDeleteForm from '../../../components/AdminModalDeleteForm';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { fetchAllAdmins, newAdmin } from '../../../redux/admin/actions';

function Administrator () {
  const dispatch = useDispatch();

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [idSelectedEdit, setIdSelectedEdit]: any = useState();
  const [idSelectedDelete, setIdSelectedDelete]: any = useState();

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchAllAdmins());
  }, [dispatch]);

  const { loadingAdmins, dataAdmins, creating } = useSelector((state: any) => state.admin);

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Comptes d’administrateur',
    },
  ];

  const createItem = (data: any, callback: any) => {
    dispatch(newAdmin({email: data.email, password: data.password, username: data.name}, callback));
  };

  const handleEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  }

  const handleDelete = (text: any) => {
    setIdSelectedDelete(text);
    setIsModalDeleteVisible(true);
  }

  return (
    <div>
      <PageHeader
        title="Comptes d’administrateur"
        ghost={false}
        breadcrumb={{ routes }}
      />
      <div className="top_content_container">
        <AdminModalForm
          openButtonText="Ajouter"
          modalTitle="Créer un compte administrateur"
          successMessage="Administrateur créé avec succès"
          onConfirm={createItem}
          confirmLoading={creating}
        />
      </div>
      <div className="content_container">
        <Table
          style={{
            position: 'absolute',
            right: '1.6%',
            width: '83%',
            marginTop: '20px',
          }}
          dataSource={dataAdmins}
          loading={loadingAdmins}
          rowKey="id"
          columns={[
            {
              title: 'Administrateurs',
              dataIndex: 'username',
              key: 'username',
            },
            {
              title: 'Courrier électronique',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Modifier',
              dataIndex: 'id',
              key: 'edit',
              render: (text: any, record: any) => (
                <div onClick={() => handleEdit(text)}>
                  <EditOutlined
                    style={{ fontSize: '16px', color: '#089EB7' }}
                  />
                </div>
              ),
            },
            {
              title: 'Réprimer',
              dataIndex: 'id',
              key: 'delete',
              render: (text: any, record: any) => (
                <div onClick={() => handleDelete(text)}>
                  <DeleteOutlined
                    style={{ fontSize: '16px', color: 'black'}}
                  />
                </div>
              ),
            }
          ]}
        />
        <AdminModalEditForm
          modalTitle="Modifier un compte administrateur"
          isModalVisible={isModalEditVisible}
          onCancel={() => setIsModalEditVisible(false)}
          idSelectedEdit={idSelectedEdit}
          data={dataAdmins}
        />
        <AdminModalDeleteForm
          modalTitle="Supprimier un compte administrateur"
          isModalVisible={isModalDeleteVisible}
          onCancel={() => setIsModalDeleteVisible(false)}
          idSelectedEdit={idSelectedDelete}
          data={dataAdmins}
        />
      </div>
    </div>
  );
}

export default Administrator;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import statisticsServices from './services'

interface dates {
  startDate: string,
  endDate: string
}

export const fetchStatisticsByAge = createAsyncThunk('statistics/allStatisticsByAge', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsByAge(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsBySex = createAsyncThunk('statistics/allStatisticsBySex', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsBySex(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsBySexAgain = createAsyncThunk('statistics/allStatisticsBySexAgain', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsBySexAgain(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsBySeasson = createAsyncThunk('statistics/allStatisticsBySeasson', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsBySeasson(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsByFruit = createAsyncThunk('statistics/allFruitsProducts', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsByFruit(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsByMeat = createAsyncThunk('statistics/allMeatProducts', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsByMeat(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsByLocal = createAsyncThunk('statistics/allLocalProducts', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsByLocal(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsByGlobalImpact = createAsyncThunk('statistics/empreinteGlobale', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsByGlobalImpact(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsByNutritionalImpact = createAsyncThunk('statistics/empreinteNutritionnel', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsByNutritionalImpact(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsBySports = createAsyncThunk('statistics/sports', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsBySports(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsByAir = createAsyncThunk('statistics/air', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsByAir(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsByEau = createAsyncThunk('statistics/eau', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsByEau(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})

export const fetchStatisticsBySols = createAsyncThunk('statistics/sols', async (data: dates, { rejectWithValue }) => {
  try {
    const response = await statisticsServices.fetchStatisticsBySols(data)
    return response?.data?.data
  } catch (err) {
    return rejectWithValue({
      error: 'Une erreur est survenue',
    })
  }
})


const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    statistics: null,
    statisticsBySex: null,
    statisticsBySexAgain: null,
    statisticsBySeasson: null,
    statisticsByFruit: null,
    statisticsByMeat: null,
    statisticsByLocal: null,
    statisticsByGaspillage: null,
    statisticsByEmpreinteGlobal: null,
    statisticsByEmpreinteNutritionnel: null,
    statisticsBySports: null,
    statisticsByAir: null,
    statisticsByEau: null,
    statisticsBySols: null,
    fetchStatisticsLoading: false,
    fetchStatisticsError: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStatisticsByAge.pending.toString(), (state) => {
      state.fetchStatisticsLoading = true
      state.fetchStatisticsError = null
    })
      .addCase(fetchStatisticsByAge.fulfilled.toString(), (state, { payload }: any) => {
        state.statistics = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByAge.rejected.toString(), (state, { payload }: any) => {
        state.statistics = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySex.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySex.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsBySex = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySex.rejected.toString(), (state, { payload }: any) => {
        state.statisticsBySex = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySexAgain.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySexAgain.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsBySexAgain = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySexAgain.rejected.toString(), (state, { payload }: any) => {
        state.statisticsBySexAgain = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySeasson.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySeasson.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsBySeasson = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySeasson.rejected.toString(), (state, { payload }: any) => {
        state.statisticsBySeasson = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByFruit.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByFruit.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsByFruit = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByFruit.rejected.toString(), (state, { payload }: any) => {
        state.statisticsByFruit = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByMeat.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByMeat.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsByMeat = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByMeat.rejected.toString(), (state, { payload }: any) => {
        state.statisticsByMeat = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByLocal.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByLocal.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsByLocal = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByLocal.rejected.toString(), (state, { payload }: any) => {
        state.statisticsByLocal = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByGlobalImpact.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByGlobalImpact.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsByEmpreinteGlobal = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByGlobalImpact.rejected.toString(), (state, { payload }: any) => {
        state.statisticsByEmpreinteGlobal = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByNutritionalImpact.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByNutritionalImpact.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsByEmpreinteNutritionnel = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByNutritionalImpact.rejected.toString(), (state, { payload }: any) => {
        state.statisticsByEmpreinteNutritionnel = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySports.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySports.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsBySports = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySports.rejected.toString(), (state, { payload }: any) => {
        state.statisticsBySports = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByAir.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByAir.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsByAir = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByAir.rejected.toString(), (state, { payload }: any) => {
        state.statisticsByAir = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByEau.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByEau.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsByEau = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsByEau.rejected.toString(), (state, { payload }: any) => {
        state.statisticsByEau = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySols.pending.toString(), (state) => {
        state.fetchStatisticsLoading = true
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySols.fulfilled.toString(), (state, { payload }: any) => {
        state.statisticsBySols = payload
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
      .addCase(fetchStatisticsBySols.rejected.toString(), (state, { payload }: any) => {
        state.statisticsBySols = payload.error
        state.fetchStatisticsLoading = false
        state.fetchStatisticsError = null
      })
  },
})

export default statisticsSlice

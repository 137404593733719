import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, message, Select, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { editFood } from "../../redux/food";
import { useForm, Controller } from "react-hook-form";
import calnut from "pages/dashboard/calculator/calnut";
import { fetchFoods } from 'redux/food';

function FoodModalEditForm(props: {
  modalTitle: string;
  isModalVisible: boolean;
  onCancel: any;
  idSelectedEdit: string;
  data: any;
}) {
  let { modalTitle, isModalVisible, onCancel, idSelectedEdit, data } = props;

  const { control, handleSubmit, reset, setValue } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });

  const dispatch = useDispatch();
  const [currentData, setData] = useState(data);
  const [selectedFood, setSelectedFood]: any = useState()
  const { creatingError, created } = useSelector((state: any) => state.food);
  const [calnutVal, setCalnutVal]: any = useState([])
  const [fields, setFields]: any = useState([]);
  const [category, setCategory]: any = useState([]);
  const [previewData, setPreviewData] = useState({})
  const [file, setFile] = useState()
  const [form] = Form.useForm();
  const [image, setImage]: any = useState()

  const successEdit = () => {
    onCancel();
    form.resetFields();
    message.success('Succès');
    dispatch(fetchFoods())
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('values: ', values)
        const formData = new FormData()
        formData.append('imagen', image)
        Object.keys(values).map((key, index) => {
          formData.append(key, values[key])
        })
        onOk(formData)
      })
      .then(() => {
        successEdit()
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }

  const onOk = (data: any) => {
    let dataValues: any = {
      id: selectedFood.id,
      data: data
    }
    dispatch(editFood(dataValues))
  };


  useEffect(() => {
    let ageInfo, impactingInfo
    if (selectedFood?.age) ageInfo = '+18 (Adulte)'
    else ageInfo = '-18 (Jeune)'
    if (selectedFood?.impacting) impactingInfo = 'Oui'
    else impactingInfo = 'Non'
    try{
    setImage(selectedFood?.image)
    setFields([
      {
        name: ['image'],
        value: selectedFood?.image,
      },
      {
        name: ['nom'],
        value: selectedFood?.nom,
      },
      {
        name: ['example'],
        value: selectedFood?.example,
      },
      {
        name: ['color'],
        value: selectedFood?.color,
      },
      {
        name: ['message'],
        value: selectedFood?.message,
      },
      {
        name: ['categoryId'],
        value: calnutVal?.list[0].categoryId,
      },
      {
        name: ['adulte'],
        value: ageInfo,
      },
      {
        name: ['impactant'],
        value: impactingInfo,
      },
      {
        name: ['portion'],
        value: calnutVal?.list[0].portion
      },
      {
        name: ['nrj_kcal'],
        value: calnutVal?.list[0].nrj_kcal
      },
      {
        name: ['eau_g'],
        value: calnutVal?.list[0].eau_g
      },
      {
        name: ['sel_g'],
        value: calnutVal?.list[0].sel_g
      },
      {
        name: ['sodium_mg'],
        value: calnutVal?.list[0].sodium_mg
      },
      {
        name: ['calcium_mg'],
        value: calnutVal?.list[0].calcium_mg
      },
      {
        name: ['fer_mg'],
        value: calnutVal?.list[0].fer_mg
      },
      {
        name: ['zinc_mg'],
        value: calnutVal?.list[0].zinc_mg
      },
      {
        name: ['iode_mg'],
        value: calnutVal?.list[0].iode_mg
      },
      {
        name: ['proteines_g'],
        value: calnutVal?.list[0].proteines_g
      },
      {
        name: ['sucres_g'],
        value: calnutVal?.list[0].sucres_g
      },
      {
        name: ['sucre_g_sans_lactose'],
        value: calnutVal?.list[0].sucre_g_sans_lactose
      },
      {
        name: ['ags_g'],
        value: calnutVal?.list[0].ags_g
      },
      {
        name: ['vitamine_a_mg'],
        value: calnutVal?.list[0].vitamine_a_mg
      },
      {
        name: ['vitamine_c_mg'],
        value: calnutVal?.list[0].vitamine_c_mg
      },
      {
        name: ['vitamine_b12_mcg'],
        value: calnutVal?.list[0].vitamine_b12_mcg
      },
      {
        name: ['vitamine_b9_mcg'],
        value: calnutVal?.list[0].vitamine_b9_mcg
      }
    ])}
    catch{
      setFields([])
    }
  }, [calnutVal, selectedFood])

  useEffect(() => {
    const info: any = data?.find((e: any) => e.id === idSelectedEdit);
    calnutInfo(info?.nom)
    setSelectedFood(info)
  }, [data, idSelectedEdit])

  const calnutInfo = async(data: any) => {
    let calnut = await getCalnut(data)
    setCalnutVal(calnut)
    getCategory()
  }


  const getCalnut = async(data: string) => {
    const datos: any = {"productName": data}
    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/calculator/listSearchCalnut`, {
        method: 'POST',
        body: JSON.stringify(datos),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      let res = await response.json()
      return(res)
    } catch (error) {
      console.log('plan'+error)
    }
  }


  const getBase64 =(file: any)=>{
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
  }


  const handleChange = ( fileList: any ) => setFile(fileList);

  const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
        setPreviewData({...previewData, previewImage: file.url || file.preview})
        setPreviewData({...previewData, previewVisible: true})
        setPreviewData({...previewData, previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),})
  }

  console.log(previewData)

  const getCategory = async() => {
    try {
      let response = await fetch(process.env.REACT_APP_BASE_URL+'/api/calculator/listCate', {
        method: 'GET'
      })
      let res = await response.json()
      if(res.list.length > 0){
        setCategory(res.list)
      }
    } catch (error) {
      console.log('plan'+error)
    }
  }

  const handleImage = async (info: any) => {
    setImage(info)
    return false
  }

  return (
    <>
      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Modifier
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} fields={fields}>
          <Form.Item
            name="image"
          >
            <Upload
              name='image'
              beforeUpload={handleImage}
            >
              <Button
                key="add"
                style={{
                  display: 'inline-block',
                  marginLeft: '40px',
                  border: '1px solid #089EB7',
                  backgroundColor: 'white',
                  color: '#089EB7',
                  borderRadius: '4px'
                }}
              >
                Ajouter
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label=""
            name="image"
          >
            {selectedFood === null || selectedFood === undefined
              ? <div style={{width: '78px', height: '78px', borderRadius: '39px', backgroundColor: '#D9D9D9'}} />
              : <div style={{width: '78px', height: '78px',  borderRadius: '39px'}} >
                <img src={process.env.REACT_APP_BASE_URL + '/' + selectedFood.image}/>
              </div>
            }  
          </Form.Item>

          <Form.Item
            label=""
            name="image"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Nom:"
            name="nom"
            rules={[
              { required: true, message: `Veuillez saisir le nom de l'aliment` },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Example:"
            name="example"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: false},
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Color"
            name="color"
            rules={[
              { required: true }
            ]}
            validateStatus={creatingError && 'error'}
            help={creatingError || null}
          >
            <Select optionFilterProp="children">
              <Select.Option key="Rouge" value="Rouge">Rouge</Select.Option>
              <Select.Option key="Jaune" value="Jaune">Jaune</Select.Option>
              <Select.Option key="Bleu" value="Bleu">Bleu</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: false}]}
          >
            <Input.TextArea showCount />
          </Form.Item>

          <Form.Item
            label="Catégorie"
            name="categoryId"
            rules={[
              { required: true, message: `Veuillez entrer la catégorie d'aliments` },
            ]}
          >
            <Select>
              {category.map((cat: any, index: any) => {return(
                <Select.Option key={index} value={cat.id}>{cat.name}</Select.Option>
              )})}
            </Select>
          </Form.Item>

          {/* <Form.Item
            label="Valeur"
            name="value"
            rules={[
              { required: true, message: `Entrez le valeur de l'aliment` }
            ]}
            help={creatingError || null}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            label="Âge"
            name="adulte"
            rules={[
              { required: true, message: `Veuillez indiquer si vous avez l'âge légal` }
            ]}
          >
            <Select optionFilterProp="children">
              <Select.Option key="adulte" value="adulte">+18 (Adulte)</Select.Option>
              <Select.Option key="jeune" value="jeune">-18 (Jeune)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Impactant ou pas"
            name="impactant"
            rules={[
              { required: true, message: `Veuillez indiquer si cela a un impact sur l'environnementl` }
            ]}
          >
            <Select optionFilterProp="children">
              <Select.Option key="oui" value="oui">Oui</Select.Option>
              <Select.Option key="non" value="non">Non</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Portion:"
            name="portion"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="nrj_kcal:"
            name="nrj_kcal"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="eau_g:"
            name="eau_g"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="sel_g:"
            name="sel_g"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="sodium_mg:"
            name="sodium_mg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="calcium_mg:"
            name="calcium_mg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="fer_mg:"
            name="fer_mg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="zinc_mg:"
            name="zinc_mg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="iode_mg:"
            name="iode_mg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="proteines_g:"
            name="proteines_g"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="sucres_g:"
            name="sucres_g"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="sucre_g_sans_lactose:"
            name="sucre_g_sans_lactose"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ags_g:"
            name="ags_g"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="vitamine_a_mg:"
            name="vitamine_a_mg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="vitamine_c_mg:"
            name="vitamine_c_mg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="vitamine_b12_mcg:"
            name="vitamine_b12_mcg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="vitamine_b9_mcg:"
            name="vitamine_b9_mcg"
            rules={[
              { required: true},
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default FoodModalEditForm;

import React, { useEffect, useState } from 'react';
import {
  PageHeader,
  Table,
  Typography,
  Empty
} from 'antd';
import FoodModalForm from '../../../components/FoodModalForm';
import FoodModalEditForm from '../../../components/FoodModalEditForm';
import FoodModalDeleteForm from '../../../components/FoodModalDeleteForm';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { fetchFoods, createFood } from '../../../redux/food'
import { Button, Modal, Form, Input, message, Select, Upload } from "antd";

function FoodManagement() {
  const dispatch = useDispatch();

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [idSelectedEdit, setIdSelectedEdit]: any = useState();
  const [idSelectedDelete, setIdSelectedDelete]: any = useState();
  

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchFoods());
  }, [dispatch]);

  const { fetchFoodloading, foods, creating } = useSelector((state: any) => state.food);

  

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Gestion des aliments'
    },
  ];

  const createItem = (data: any) => {
    console.log('data created: ', data.get('values'))
    console.log('data created: ', data.get('image'))

    dispatch(createFood(data))
    /* dispatch(createFood({
      image: data.image,
      nom: data.value.nom,
      example: data.value.example,
      color: data.value.color,
      message: data.value.message,
      categoryId: Number(data.value.categoryId),
      adulte: data.value.adulte,
      impactant: data.value.impactant,
      portion: data.value.portion,
			nrj_kcal: data.value.nrj_kcal,
			eau_g: data.value.eau_g,
			sel_g: data.value.sel_g,
			sodium_mg: data.value.sodium_mg,
			calcium_mg: data.value.calcium_mg,
			fer_mg: data.value.fer_mg,
			zinc_mg: data.value.zinc_mg,
			iode_mg: data.value.iode_mg,
			proteines_g: data.value.proteines_g,
			sucres_g: data.value.sucres_g,
			sucre_g_sans_lactose: data.value.sucre_g_sans_lactose,
			ags_g: data.value.ags_g,
			vitamine_a_mg: data.value.vitamine_a_mg,
			vitamine_c_mg: data.value.vitamine_c_mg,
			vitamine_b12_mcg: data.value.vitamine_b12_mcg,
			vitamine_b9_mcg: data.value.vitamine_b9_mcg
    })
    ); */
    // var datos: any = ({
    //   image: data.image,
    //   nom: data.nom,
    //   categoryId: Number(data.categoryId),
    //   value: data.value,
    //   adulte: data.adulte,
    //   impactant: data.impactant
    // })
    // console.log(datos)
  };

  const handleEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  }

  const handleDelete = (text: any) => {
    setIdSelectedDelete(text);
    setIsModalDeleteVisible(true);
  }

  return (
    <div>
      <PageHeader
        title="Gestion des aliments"
        ghost={false}
        breadcrumb={{ routes }}
      />
      <div className="top_content_container">
        <FoodModalForm
          openButtonText="Ajouter"
          modalTitle="Créer un nouvel aliment"
          successMessage="Aliment créé avec succès"
          onConfirm={createItem}
          confirmLoading={creating}
        />
      </div>
      <div className="content_container" style={{ paddingBottom: '150vh' }}>
        <Table
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Pas de données.' /> }}
          style={{
            position: 'absolute',
            right: '1.6%',
            width: '83%',
            marginTop: '20px',
          }}
          dataSource={foods}
          loading={fetchFoodloading}
          rowKey="id"
          columns={[
            {
              title: '',
              dataIndex: 'image',
              key: 'image',
              width: '100px',
              align: 'center',
              render: (text: any, record: any) => {
                return <div style={{ backgroundColor: '#D9D9D9', width: '32px', marginLeft: '30px' }}><img width={32} src={`${process.env.REACT_APP_BASE_URL}/${text}`} alt={text} /></div>
              },
            },
            {
              title: 'Nom',
              dataIndex: 'nom',
              key: 'name',
              align: 'center',
            },
            {
              title: 'Example',
              dataIndex: 'example',
              key: 'example',
              align: 'center',
            },
            {
              title: 'Color',
              dataIndex: 'color',
              key: 'color',
              align: 'center',
            },
            {
              title: 'Message',
              dataIndex: 'message',
              key: 'message',
              align: 'center',
            },
            {
              title: 'Catégorie',
              dataIndex: 'categoryName',
              key: 'categoryName',
              align: 'center',
            },
            {
              title: 'Valeurs',
              dataIndex: 'portion',
              key: 'portion',
              align: 'center',
              render: (text: any, record: any) => (
                <Typography.Text>{text}g</Typography.Text>
              ),
            },
            {
              title: 'Âge',
              dataIndex: 'adulte',
              align: 'center',
              key: 'adulte',
              render: (text: any, record: any) => {
                if (text === true) return <Typography.Text>+18 (Adulte)</Typography.Text>
                else return <Typography.Text>-18 (Jeune)</Typography.Text>
              },
            },
            {
              title: 'Impactant ou pas',
              dataIndex: 'impacting',
              align: 'center',
              key: 'impacting',
              render: (text: any, record: any) => {
                if (text === true) return <Typography.Text>Oui</Typography.Text>
                else return <Typography.Text>Non</Typography.Text>
              },
            },
            {
              title: 'Modifier',
              dataIndex: 'id',
              align: 'center',
              key: 'edit',
              render: (text: any, record: any) => (
                <div onClick={() => handleEdit(text)}>
                  <EditOutlined
                    style={{ fontSize: '16px', color: '#089EB7' }}
                  />
                </div>
              ),
            },
            {
              title: 'Réprimer',
              dataIndex: 'id',
              align: 'center',
              key: 'delete',
              render: (text: any, record: any) => (
                <div onClick={() => handleDelete(text)}>
                  <DeleteOutlined
                    style={{ fontSize: '16px', color: 'black' }}
                  />
                </div>
              ),
            }
          ]}
        />
        <FoodModalEditForm
          modalTitle="Modifier un nouvel aliment"
          isModalVisible={isModalEditVisible}
          onCancel={() => setIsModalEditVisible(false)}
          idSelectedEdit={idSelectedEdit}
          data={foods}
        />
        <FoodModalDeleteForm
          modalTitle="Supprimier un aliment"
          isModalVisible={isModalDeleteVisible}
          onCancel={() => setIsModalDeleteVisible(false)}
          idSelectedDelete={idSelectedDelete}
          data={foods}
        />
      </div>
    </div>
  );
}

export default FoodManagement;


const adminConstants = {
  SET_LOADING_ADMINS: 'SET_LOADING_ADMINS',
  SET_ADMINS: 'SET_ADMINS',

  SET_NEW_ADMIN: 'SET_NEW_ADMIN',
  NEW_ADMIN_ERROR: 'NEW_ADMIN_ERROR',

  SET_DELETING_ADMIN: 'SET_DELETING_ADMIN',
  SET_EDITING_ADMIN: 'SET_EDITING_ADMIN',
};

export default adminConstants;
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableCustom from "../../../../components/Table";
import { listAnc as list} from "../../../../redux/calculator";
import { data, createJSONANC } from "./conf";

function EnergyPage() {
  const dispatch = useDispatch();
  const { loadingListAnc, listAnc} = useSelector(
    (state: any) => state.calculator
  );

  useEffect(() => {
    dispatch(list({ callback: () => {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(createJSONANC(listAnc))

  return (
    <>
      <TableCustom
        bordered
        title={'ANC'}
        header={'ANC'}
        loading={loadingListAnc || !listAnc ? true : false}
        data={listAnc}
        columns={data}
      />
    </>
  );
}

export default EnergyPage;

export const data = [
  {
    title: "Categorie",
    dataIndex: "category",
    key: "category",
    align: 'center',
  },
  {
    title: 'Score unique EF (mPt/kg de produit)',
    dataIndex: 'score_unique_EF',
    key: 'score_unique_EF',
    align: 'center',
  },
  {
    title: 'Changement climatique (kg CO2 eq/kg de produit)',
    dataIndex: 'changement_climatique',
    key: 'changement_climatique',
    align: 'center',
  },
  {
    title: "Appauvrissement de la couche d'ozone (E-06 kg CVC11 eq/kg de produit)",
    dataIndex: "changement_climatique",
    key: "changement_climatique",
    align: 'center',
  },
  {
    title: 'Rayonnements ionisants (kBq U-235 eq/kg de produit)',
    dataIndex: 'rayonnements_ionisants',
    key: 'rayonnements_ionisants',
    align: 'center',
  },
  {
    title: 'Formation photochimique dozone (E-03 kg NMVOC eq/kg de produit)',
    dataIndex: 'formation_photochimique',
    key: 'formation_photochimique',
    align: 'center',
  },
  {
    title: "Particules (E-06 disease inc./kg de produit)",
    dataIndex: "particules",
    key: "particules",
    align: 'center',
  },
  {
    title: 'Acidification terrestre et eaux douces (mol H+ eq/kg de produit)',
    dataIndex: 'acidification_terrestre_et_eaux_douces',
    key: 'acidification_terrestre_et_eaux_douces',
    align: 'center',
  },
  {
    title: "Eutrophisation terreste (mol N eq/kg de produit)",
    dataIndex: "eutrophisation_terreste",
    key: "eutrophisation_terreste",
    align: 'center',
  },
  {
    title: 'Eutrophisation eaux douces (E-03 kg P eq/kg de produit)',
    dataIndex: 'eutrophisation_eaux_douces',
    key: 'eutrophisation_eaux_douces',
    align: 'center',
  },
  {
    title: 'Eutrophisation marine (E-03 kg N eq/kg de produit)',
    dataIndex: 'eutrophisation_marine',
    key: 'eutrophisation_marine',
    align: 'center',
  },
  {
    title: "MaxiUtilisation du sol (Pt/kg de produit)mum",
    dataIndex: "utilisation_du_sol",
    key: "utilisation_du_sol",
    align: 'center',
  },
  {
    title: 'Écotoxicité pour écosystèmes aquatiques deau douce (CTUe/kg de produit)',
    dataIndex: 'ecotoxicite_pour_ecosystemes_aquatiques',
    key: 'ecotoxicite_pour_ecosystemes_aquatiques',
    align: 'center',
  },
  {
    title: 'Épuisement des ressources eau (m3 depriv./kg de produit)',
    dataIndex: 'epuisement_des_ressources_eau',
    key: 'epuisement_des_ressources_eau',
    align: 'center',
  },
  {
    title: 'Épuisement des ressources energetiques (MJ/kg de produit)',
    dataIndex: 'epuisement_des_ressources_energetiques',
    key: 'epuisement_des_ressources_energetiques',
    align: 'center',
  },
  {
    title: 'Épuisement des ressources minéraux (E-06 kg Sb eq/kg de produit)',
    dataIndex: 'epuisement_des_essources_minéraux',
    key: 'epuisement_des_essources_minéraux',
    align: 'center',
  }
];

export const columns = [{}];

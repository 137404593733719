import { act } from 'react-dom/test-utils';
import usersConstants from './constants';

const {
  SET_LOADING_USERS,
  SET_USERS,
  SET_HOME,
  CHECKING_HOME,
  SET_EDITING_USER,
  SET_EDIT_ERROR,
  SET_EDIT_OK,
} = usersConstants;

const initialState = {
  loading: false,
  data: [],
  home: [],
  editOk: false,
};

const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_USERS: {
      return {
        ...state,
        loading: action.isLoadingFeedbacks,
      };
    }
    case SET_USERS: {
      return {
        ...state,
        users: action.users,
      };
    }
    case SET_HOME: {
      return {
        ...state,
        home: action.home,
      };
    }
    case CHECKING_HOME: {
      return {
        ...state,
        checkingHome: action.isChecking,
      };
    }
    case SET_EDITING_USER: {
      return {
        ...state,
        isEditingUser: action.isEditingUser,
      };
    }
    case SET_EDIT_ERROR: {
      return {
        ...state,
        editError: action.editError,
      };
    }
    case SET_EDIT_OK: {
      return {
        ...state,
        editOk: action.editOk,
      };
    }
    default: {
      return state;
    }
  }
};

export default usersReducer;

import api from '../../helpers/api';

const statisticsServices = {
  fetchStatisticsByAge: (data: any) => {
    return api.get(`/statistics/allStatisticsByAge/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsBySex: (data: any) => {
    return api.get(`/statistics/allStatisticsBySex/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsBySexAgain: (data: any) => {
    return api.get(`/statistics/allStatisticsBySexAgain/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsBySeasson: (data: any) => {
    return api.get(`/statistics/allSeassonProducts/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsByFruit: (data: any) => {
    return api.get(`/statistics/allFruitsProducts/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsByMeat: (data: any) => {
    return api.get(`/statistics/allMeatProducts/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsByLocal: (data: any) => {
    return api.get(`/statistics/allLocalProducts/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsByGlobalImpact: (data: any) => {
    return api.get(`/statistics/empreinteGlobale/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsByNutritionalImpact: (data: any) => {
    return api.get(`/statistics/empreinteNutritionnel/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsBySports: (data: any) => {
    return api.get(`/statistics/sports/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsByAir: (data: any) => {
    return api.get(`/statistics/air/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsByEau: (data: any) => {
    return api.get(`/statistics/eau/${data.startDate}/${data.endDate}`);
  },
  fetchStatisticsBySols: (data: any) => {
    return api.get(`/statistics/sols/${data.startDate}/${data.endDate}`);
  },
};

export default statisticsServices;

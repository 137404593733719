import React from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Divider } from 'antd';

import styles from './styles.module.scss';
import logo from '../../assets/img/logo-waterfamily.png';
import background from '../../assets/img/background.png';
import { loginUser } from '../../redux/user/actions';

function Login() {
  const { loggingIn, loginError } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const onLoginSuccess = () => {
    history.push('/admins');
  };

  const onFinish = (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    const { email, password, remember } = values;
    const data = { email, password };
    dispatch(loginUser(data, remember, onLoginSuccess));
  };


  return (
    <div style={{
      height: "100vh", maxWidth: "1400px", margin: "0px auto", backgroundPosition: "center"
      , backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"
    }}>

      <div className={styles.login_form_container}>
        <img src={logo} alt="Logo" className={styles.login_form_logo} />
        <Form
          name="normal_login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            className={styles.space}
            name="email"
            rules={[
              { required: true, message: 'Veuillez entrer votre email' },
              { type: 'email', message: 'Entrer une adresse email valide' },
            ]}
            validateStatus={loginError && 'error'}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Courrier électronique"
              disabled={loggingIn}
              style={{ color: '#089EB7' }}
            />
          </Form.Item>
          <Form.Item
            className={styles.space}
            name="password"
            rules={[
              { required: true, message: 'Veuillez entrer votre mot de passe' },
            ]}
            validateStatus={loginError && 'error'}
            help={loginError || null}
          >
            <Input
              prefix={
                <LockOutlined
                  className="site-form-item-icon"
                  style={{ color: '#089EB7' }}
                />
              }
              type="password"
              placeholder="Mot de passe"
              disabled={loggingIn}
            />
          </Form.Item>
          <Form.Item className={styles.space}>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox disabled={loggingIn} style={{ float: 'left' }}>
                Rappelle moi
              </Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item className={styles.space}>
            <Link className={styles.login_form_forgot} to="/recover/request">
              Tu as oublié le mot de passe?
            </Link>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login_form-submit"
              disabled={loggingIn}
              block
            >
              Montez
            </Button>
          </Form.Item>
        </Form>
      </div>

    </div>
  );
}

export default Login;

import React from "react";
import { Input } from "antd";
import { Controller } from "react-hook-form";

const Block = (props: { title: string; data: Array<any>; first?: boolean ; handleChange?: (value: any)=> void; control?: any; name: string; value?: any}) => {
  const { title, data, first, control, name, value } = props;

  return (
    <div
      style={{
        paddingLeft: "65px",
      }}
    >
      <div
        style={{ paddingBottom: "26px", paddingTop: first ? "21px" : "34px" }}
      >
        <span
          style={{
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: "22px",
            lineHeight: "28px",
          }}
        >
          {title}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexFlow: "row wrap",
          paddingLeft: "77px",
        }}
      >
        {data.map((ind, index) => {
          return (
          <>
            <span style={{ paddingRight: "17px" }}>{ind} :</span>
            <div style={{ paddingRight: "34px" }}>
              <Controller
                control={control}
                name={`${name}${index}`}
                defaultValue={value && value[Object.keys(value)[index]]}
                rules={{ required: "Necesitas incluir una cantidad válida" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <Input
                      type='number'
                      style={{ width: "72px" }}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            </div>
          </>
        )})}
      </div>
    </div>
  );
};

export default Block;

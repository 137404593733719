import api from "../../helpers/api";


const categoriesServices = {
  fetch: (query: any) => {
    return api.get("/categories", { params: { ...query } });
  },
  create: (data: any) => {
    return api.post("/category/create", data);
  },
  edit: (id: any, data: any) => {
    return api.put('/category/edit/' + id, data);
  },
  delete: (id: string) => {
    return api.delete("/category/delete/" + id);
  },
};

export default categoriesServices;
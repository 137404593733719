import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import categoriesServices from './services'

interface Delete {
  id: string,
  callback?: () => void,
}

interface Edit {
  id: string,
  data: any,
  callback?: () => void,
}

interface Create {
  id: any,
  name: any
}

// export const fetchFoods = createAsyncThunk('category/fetchFoods', async (_, { rejectWithValue }) => {
// 	try {
// 		const response = await foodServices.fetchAll()
//     return response?.data?.list
// 	} catch (err) {
// 		return rejectWithValue({
// 			error: 'Une erreur est survenue',
// 		})
// 	}
// })

export const deleteCategory = createAsyncThunk('category/delete', async (data: Delete, { rejectWithValue }) => {
	try {
		const response = await categoriesServices.delete(data.id)
    if (data.callback) data.callback()
    return response?.data
	} catch (err: any) {
    let errorMessage
    if (err?.response?.data?.status === 401) errorMessage = 'Utilisateur non trouvé'
    else if (err?.response?.data?.status === 404) errorMessage = 'Aliment non trouvé'
    else errorMessage = 'Une erreur est survenue'
		return rejectWithValue({
			error: errorMessage
		})
	}
})

export const editCategory = createAsyncThunk('category/edit', async (data: Edit, { rejectWithValue }) => {
	try {
		const response = await categoriesServices.edit(data.id, data.data)
    if (data.callback) data.callback()
    return response?.data
	} catch (err: any) {
    let errorMessage
    if (err?.response?.data?.status === 404) errorMessage = 'Aliment non trouvé'
    else errorMessage = 'Une erreur est survenue'
		return rejectWithValue({
			error: errorMessage
		})
	}
})

export const createCategory = createAsyncThunk('category/create', async (data: Create, { rejectWithValue }) => {
	try {
		const response = await categoriesServices.create(data)
    return response?.data
	} catch (err: any) {
    let errorMessage
    if (err?.response?.data?.status === 404) errorMessage = 'Aliment non trouvé'
    else errorMessage = 'Une erreur est survenue'
		return rejectWithValue({
			error: errorMessage
		})
	}
})

// const foodSlice = createSlice({
// 	name: 'food',
// 	initialState: {
// 		fetchFoodsLoading: false,
// 		foods: null,
// 		fetchFoodsError: null,
//     creating: false,
//     creatingError: null,
//     created: false,
// 	},
// 	reducers: {},
// 	extraReducers: builder => {
//     builder.addCase(fetchFoods.pending.toString(), (state) => {
//       state.fetchFoodsLoading = true
// 			state.fetchFoodsError = null
//     })
//     .addCase(fetchFoods.fulfilled.toString(), (state, { payload }: any) => {
//       state.foods = payload
// 			state.fetchFoodsLoading = false
// 			state.fetchFoodsError = null
//     })
//     .addCase(fetchFoods.rejected.toString(), (state, { payload }: any) => {
//       state.foods = payload.error
// 			state.fetchFoodsLoading = false
// 			state.fetchFoodsError = null
//     })
//     .addCase(createFood.pending.toString(), (state) => {
//       state.creating = true
//       state.created = false
//       state.creatingError = null
//     })
//     .addCase(createFood.fulfilled.toString(), (state) => {
//       state.creating = false
//       state.created = true
//       state.creatingError = null
//     })
//     .addCase(createFood.rejected.toString(), (state, { payload }: any) => {
//       state.creating = false
//       state.created = false
//       state.creatingError = payload.error
//     })
// 	},
// })

// export default foodSlice

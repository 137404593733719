
export const data = [
  {
    title: "Categorie",
    dataIndex: "name",
    key: "name",
    align: "left",
  },
];

export const dataExpandable = [
  {
    title: "Portion",
    dataIndex: "portion",
    key: "portion",
    align: "left",
  },
  {
    title: "Nom du produit",
    dataIndex: "nom",
    key: "nom",
    align: "left",
  },
  {
    title: "nrj_kcal",
    dataIndex: "nrj_kcal",
    key: "nrj_kcal",
    align: "left",
  },
  {
    title: "eau_g",
    dataIndex: "eau_g",
    key: "eau_g",
    align: "left",
  },
  {
    title: "sel_g",
    dataIndex: "sel_g",
    key: "sel_g",
    align: "left",
  },
  {
    title: "sodium_mg",
    dataIndex: "sodium_mg",
    key: "sodium_mg",
    align: "left",
  },
  {
    title:
      "calcium_mg",
    dataIndex: "calcium_mg",
    key: "calcium_mg",
    align: "left",
  },
  {
    title: "fer_mg",
    dataIndex: "fer_mg",
    key: "fer_mg",
    align: "left",
  },
  {
    title: "zinc_mg",
    dataIndex: "zinc_mg",
    key: "zinc_mg",
    align: "left",
  },
  {
    title: "iode_mg",
    dataIndex: "iode_mg",
    key: "iode_mg",
    align: "left",
  },
  {
    title: "proteines_g",
    dataIndex: "proteines_g",
    key: "proteines_mg",
    align: "left",
  },
  {
    title: "sucres_g",
    dataIndex: "sucres_g",
    key: "sucres_g",
    align: "left",
  },
  {
    title: "sucre_g_sans_lactose",
    dataIndex: "sucre_g_sans_lactose",
    key: "sucre_g_sans_lactose",
    align: "left",
  },
  {
    title: "ags_g",
    dataIndex: "ags_g",
    key: "ags_g",
    align: "left",
  },
  {
    title: "vitamine_a_mg",
    dataIndex: "vitamine_a_mg",
    key: "vitamine_a_mg",
    align: "left",
  },
  {
    title:
      "vitamine_c_mg",
    dataIndex: "vitamine_c_mg",
    key: "vitamine_c_mg",
    align: "left",
  },
  {
    title: "vitamine_b12_mcg",
    dataIndex: "vitamine_b12_mcg",
    key: "vitamine_b12_mcg",
    align: "left",
  },
  {
    title: "vitamine_b9_mcg",
    dataIndex: "vitamine_b9_mcg",
    key: "vitamine_b9_mcg",
    align: "left",
    }
];

import api from '../../helpers/api';

const userServices = {
  me: () => {
    return api.get('/user/me');
  },
  login: (email: string, password: string, remember?: boolean) => {
    return api.post(
      '/user/signin',
      { email, password },
      { params: { remember } }
    );
  },
  logout: () => {
    return api.get('/user/signout');
  },
  userInfo: (id: string) => {
    return api.post(`/user/info/`, { id })
  },
};

export default userServices;

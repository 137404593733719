import React from 'react';
import styles from './styles.module.scss';

function Icono(props: { img: any }) {
  const { img } = props;

  return <img src={img} alt="" className={styles.img} />;
}

export default Icono;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableCustom from "../../../../components/Table";
import {useForm, Controller } from "react-hook-form";
import { listEnergy as list } from "../../../../redux/calculator";
import { data } from "./conf";

function EnergyPage() {
  const dispatch = useDispatch();
  const { loadingListEnergy, listEnergy } = useSelector(
    (state: any) => state.calculator
  );
  const [option, setOption] = useState("");
  const [currentData, setData] = useState([]);
  const [edit, setEdit] = useState({cell: '' , value: false})

  const {
    control,
    handleSubmit,
    reset,
    watch
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });

  useEffect(() => {
    dispatch(list({ callback: () => {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listEnergy) setData(listEnergy);
  }, [listEnergy]);

  useEffect(() => {
    if (option)
      setData(
        listEnergy.filter((ind: { gender: string }) => ind.gender === option)
      );
  }, [listEnergy, option]);

  const handleEdit = (data: any) => {
    setEdit({cell: data.id, value: true})
  };

  console.log(watch())

  return (
    <>
      <TableCustom
        edit = {edit}
        title={"Energie"}
        header={'Energie'}
        loading={loadingListEnergy || !currentData ? true : false}
        data={currentData}
        handleSubmit={handleSubmit}
        pageSize={20}
        isEdit
        isSelect
        columns={data({control:control, edit: edit})}
        handleEdit={handleEdit}
        option={option}
        setOption={setOption}
      />
    </>
  );
}

export default EnergyPage;

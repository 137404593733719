import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { PageHeader, Row, Col, Pagination } from 'antd'
import { Bar } from 'react-chartjs-2';
import StatistiquesByAge from "./statisticsByAge";
import StatistiquesBySex from "./statisticsBySex";
import StatistiquesBySexAgain from "./statisticsBySexAgain";
import StatistiquesBySeasson from "./statisticsBySeasson";
import StatisquesByFruit from './statisticsByFruit'
import StatistiquesByMeat from "./statisticsByMeat";
import StatistiquesByLocal from "./statisticsByLocal";
import StatisquesByGlobalImpact from './statisticsByGlobalImpact';
import StatisquesByNutritonalImpact from './statisticsByNutritionalImpact';
import StatisquesBySport from './statisticsBySport';
import StatisquesByAir from "./statisticsByAir";
import StatisquesByEau from './statisticsByEau';
import StatisquesBySols from "./statisticsBySols";

function Statistiques() {
    const dispatch = useDispatch()
    const [currentChart, setCurrentChart]: any = useState(1)
    useEffect(() => {

    }, [])
    const routes = [
        {
            path: "index",
            breadcrumbName: "Statistiques",
        },
        {
            path: "first",
            breadcrumbName: "Statistics",
        },
    ];

    const handlePagination = (value: any) => {
        setCurrentChart(value)
    }

    useEffect(() => {

    }, [currentChart])

    return (
        <>
            <PageHeader
                title="Statistiques"
                ghost={false}
                breadcrumb={{ routes }}
                style={{ backgroundColor: "#E5E5E5" }}
            />
            <Row style={{ height: '60vh' , margin: '0% 2%'}}>
            <Col style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} sm={2}>
                        <div style={{ cursor: 'pointer' }}>
                            <h2>{'<'}</h2>
                        </div>
                    </Col>
                <Col sm={20}>
                    {currentChart === 1 && <StatistiquesByAge />}
                    {currentChart === 2 && <StatistiquesBySex />}
                    {currentChart === 3 && <StatistiquesBySexAgain />}
                    {currentChart === 4 && <StatistiquesBySeasson />}
                    {currentChart === 5 && <StatisquesByFruit />}
                    {currentChart === 6 && <StatistiquesByMeat/>}
                    {currentChart === 7 && <StatistiquesByLocal/>}
                    {currentChart === 8 && <div>Aqui va el gaspillage</div>}
                    {currentChart === 9 && <StatisquesByGlobalImpact />}
                    {currentChart === 10 && <StatisquesByNutritonalImpact />}
                    {currentChart === 11 && <StatisquesBySport/>}
                    {currentChart === 12 && <StatisquesByAir/>}
                    {currentChart === 13 && <StatisquesByEau/>}
                    {currentChart === 14 && <StatisquesBySols/>}
                    <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end'}}>
                        <Pagination style={{ margin: '3% 10% 3% 0%' }} showSizeChanger={false} defaultCurrent={1} total={140} onChange={handlePagination} />
                    </div>
                </Col>
                <Col style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} sm={2}>
                        <div style={{ cursor: 'pointer' }}>
                            <h2>
                                {'>'}
                            </h2>
                        </div>
                    </Col>
            </Row>

        </>
    )
}

export default Statistiques;
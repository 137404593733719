export const chooseTypes = (name: number) => {
  let option = ''
  switch (name) {
    case 1:
      option = "Petit déjeuner"
      break;
    case 2:
      option = "Entrée"
      break;
    case 3:
      option = "Plat"
      break;
    case 4:
      option = "Dessert"
      break;
    case 5:
      option = "Gouter"
      break;
    case 6:
      option = "Apero"
      break;
    case 7:
      option = "Boissons de la journée"
      break;
    default:
      break;
  }
  return option
};
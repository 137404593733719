import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { PageHeader, Row, Col, Select } from 'antd'
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { fetchStatisticsBySeasson } from "../../../redux/statistics/index";
import styles from './styles.module.scss'
import moment from "moment";

function StatistiquesBySeasson() {
    const { Option } = Select
    const [dates, setDates] = useState({
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().endOf('year').format('YYYY-MM-DD')
    })
    const { statisticsBySeasson, fetchStatisticsLoading } = useSelector((state: { statistics: any; }) => state.statistics)
    const dispatch = useDispatch()
    const [mensYes, setMensYes]: any = useState({})
    const [girlsYes, setGirlsYes]: any = useState({})
    const [mensNo, setMensNo] = useState({})
    const [girlsNo, setGirlsNo] = useState({})
    const [years, setYears]: any = useState([])
    const [months, setMonths]: any = useState([])
    const [selectedMonth, setSelectedMonth]: any = useState(moment().month() + 1)
    const [selectedYear, setSelectedYear]: any = useState(moment().year())

    useEffect(() => {
        dispatch(fetchStatisticsBySeasson(dates))
        let currentYear = moment().year()
        let temp = []
        let temp2 = []
        for (let index = currentYear - 3; index <= currentYear + 3; index++) {
            temp.push(index)
        }
        setYears(temp)
        for (let index = 0; index < 12; index++) {
            temp2.push(moment().month(index).format('MMMM'))
        }
        setMonths(temp2)
    }, [])

    useEffect(() => {
        if (statisticsBySeasson != null) {
            setMensYes(statisticsBySeasson.mensYes)
            setGirlsYes(statisticsBySeasson.girlsYes)
            setMensNo(statisticsBySeasson.mensNo)
            setGirlsNo(statisticsBySeasson.girlsNo)
        }
    }, [fetchStatisticsLoading])

    const routes = [
        {
            path: "index",
            breadcrumbName: "Statistiques",
        },
        {
            path: "first",
            breadcrumbName: "Statistics",
        },
    ];

    const data = {
        labels: ['Les femmes qui consomment', 'Les femmes qui ne consomment pas'],
        datasets: [
            {
                data: [girlsYes, girlsNo],
                backgroundColor: [
                    'rgba(8, 158, 183, 1)',
                    'rgba(229, 233, 255, 1)'
                ],
            },
        ],
    };

    const data2 = {
        labels: ['Les hommes qui consomment', 'Les hommes qui ne consomment pas'],
        datasets: [
            {
                data: [mensYes, mensNo],
                backgroundColor: [
                    'rgba(8, 158, 183, 1)',
                    'rgba(229, 233, 255, 1)'
                ],
            },
        ],
    };

    const handleDates = (value: any, option: string) => {
        if (option == 'year') setSelectedYear(value)
        if (option == 'month') setSelectedMonth(value)
    }
    useEffect(() => {
        setDates({
            startDate: `${selectedYear}-${selectedMonth}-01`,
            endDate: `${selectedYear}-${selectedMonth}-${moment(selectedMonth, 'MM').endOf('month').format('DD')}`
        })
    }, [selectedMonth, selectedYear])

    useEffect(() => {
        dispatch(fetchStatisticsBySeasson(dates))
    }, [dates])

    return (
        <>
            <div>
                <Row style={{ backgroundColor: 'white', padding: '3% 0% 3% 10%' }}>
                    <Col sm={24}>
                        <h2>Consommation de produits de saison</h2>
                    </Col>
                    <Col sm={24}>
                        <Select className={styles.selectStyle} style={{ marginRight: '3%' }} defaultValue={moment().year()} onChange={(e) => handleDates(e, 'year')}>
                            {years.map((e: any) => {
                                return (
                                    <Option value={e}>{e}</Option>
                                )
                            })}
                        </Select>
                        <Select className={styles.selectStyle} defaultValue={moment().month()} onChange={(e) => handleDates(e + 1, 'month')}>
                            {months.map((e: any, index: any) => {
                                return (
                                    <Option value={index}>{e}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'white' }}>
                        <div style={{ backgroundColor: 'white', width: '50%', height: '50%', display: 'flex', justifyContent: 'center' }}>
                            <Pie data={data} />
                        </div>
                        <div style={{ backgroundColor: 'white', width: '50%', height: '50%', display: 'flex', justifyContent: 'center' }}>
                            <Pie data={data2} />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default StatistiquesBySeasson;
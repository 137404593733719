import React from "react";
import Arrow from "../../assets/img/arrow.png";
import styles from "./styles.module.scss";
import {chooseTypes} from './conf'

const SelectCustom = (props: {
  data: Array<string>;
  option?: string;
  select: boolean;
  setSelect: (value: boolean) => void;
  setOption?: (option: string) => void;
}) => {
  const { select, option, setSelect, setOption, data } = props;
  return (
    <div onClick={() => setSelect(!select)} className={styles.root}>
      <div className={styles.container}>
        <span style={{ color: "#47464E" }}>
          {chooseTypes(option && option)}
        </span>
        <div style={{ paddingLeft: "10px" }}>
          <img
            src={Arrow}
            alt="arrow"
            className={!select ? styles.image : styles.imageTransform}
          />
        </div>
      </div>

      {select ? (
        <div className={styles.list}>
          {data.map((ind) => {
            return (
              <div
                className={styles.item}
                style={{
                  backgroundColor: option === ind ? "#E5E9FF" : "white",
                }}
                onClick={() => setOption && setOption(ind)}
              >
                <span
                  style={{
                    color: option === ind ? "#3D43FF" : "#47464E",
                  }}
                >
                  {ind}
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SelectCustom;

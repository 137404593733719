const usersConstants = {
  SET_LOADING_USERS: 'SET_LOADING_USERS',
  SET_USERS: 'SET_USERS',
  CHECKING_HOME: 'CHECKING_HOME',
  SET_HOME: 'SET_HOME',
  SET_EDITING_USER: 'SET_EDITING_USER',
  SET_EDIT_ERROR: 'SET_EDIT_ERROR',
  SET_EDIT_OK: 'SET_EDIT_OK',
  PAGE_SIZE: 10,
};

export default usersConstants;

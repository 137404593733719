import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { PageHeader, Row, Col, Select } from 'antd'
import { Bar } from 'react-chartjs-2';
import { fetchStatisticsBySports } from "../../../redux/statistics/index";
import moment from "moment";

function StatistiquesBySport() {
    const {Option} = Select
    const [dates, setDates] = useState({
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().endOf('year').format('YYYY-MM-DD')
    })
    const { statisticsBySports, fetchStatisticsLoading } = useSelector((state: { statistics: any; }) => state.statistics)
    const dispatch = useDispatch()
    const [mens, setMens]: any = useState({})
    const [girls, setGirls]: any = useState({})
    const [years, setYears]: any = useState([])

    useEffect(() => {
        dispatch(fetchStatisticsBySports(dates))
        let currentYear = moment().year()
        let temp = []
        for (let index = currentYear-3; index <= currentYear+3; index++) {
            temp.push(index)
        }
        setYears(temp)
    }, [])

    useEffect(() => {
        if (statisticsBySports != null) {

            setMens(statisticsBySports.mens)
            setGirls(statisticsBySports.girls)
        }
    }, [fetchStatisticsLoading])

    const routes = [
        {
            path: "index",
            breadcrumbName: "Statistiques",
        },
        {
            path: "first",
            breadcrumbName: "Statistics",
        },
    ];
    const data = {
        labels: ['Sédentaire', 'Activité physique modérée', 'Activité physique intense'],
        datasets: [
            {
                label: 'Homme',
                data: [mens.mensSedent, mens.mensModerate, mens.mensIntense],
                backgroundColor: 'rgba(26, 51, 86, 1)',
            },
            {
                label: 'Femme',
                data: [girls.girlsSedent, girls.girlsModerate, girls.girlsIntense],
                backgroundColor: 'rgba(8, 158, 183, 1)',
            }
        ],
    };

    const options: any = {
        indexAxis: 'y',
        responsive: true,
        plugins: {
            legend: {
                position: 'right'
            }
        }
    };

    const handleDates = (value: any) => {
        setDates({
            startDate: `${value}-01-01`,
            endDate: `${value}-12-31`
        })
    }

    useEffect(() => {
        dispatch(fetchStatisticsBySports(dates))
    }, [dates])

    return (
        <>
            <div>
                <Row style={{ backgroundColor: 'white', padding: '3% 0% 3% 10%'}}>
                    <Col sm={24}>
                        <h2>Activité Sportive</h2>
                    </Col>
                    <Col sm={24}>
                        <Select defaultValue={moment().year()} onChange={handleDates}>
                            {years.map((e: any) => {
                                return (
                                    <Option value={e}>{e}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <div style={{ backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Bar style={{ height: '100%', width: '100%' }} data={data} options={options} />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default StatistiquesBySport;
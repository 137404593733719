import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux';
import { editAdmin } from '../../redux/admin/actions'

function AdminModalEditForm(props: {
  modalTitle: string;
  isModalVisible: boolean;
  onCancel: any;
  idSelectedEdit: string;
  data: any,
}) {
  const {
    modalTitle,
    isModalVisible,
    onCancel,
    idSelectedEdit,
    data,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [fields, setFields]: any = useState([
    {
      name: ['email'],
      value: '',
    },
    {
      name: ['username'],
      value: '',
    },
  ]);

  useEffect(() => {
    const info: any = data?.find((e: any) => e.id === idSelectedEdit);
    setFields([
      {
        name: ['email'],
        value: info?.email,
      },
      {
        name: ['username'],
        value: info?.username,
      },
    ]);
  }, [data, idSelectedEdit])

  const onSuccess = () => {
    form.resetFields();
    message.success('Changement effectué avec succès');
    onCancel()
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(editAdmin(idSelectedEdit, values));
        onSuccess()
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <>
      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Modifier
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} fields={fields}>
          <Form.Item
            label="Courrier électronique"
            name="email"
            rules={[
              { type: 'email', message: 'Entrer une adresse email valide' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Mot de passe" name="password">
            <Input type="password" />
          </Form.Item>

          <Form.Item label="Nom" name="username">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AdminModalEditForm;

import React, {useEffect, useState} from "react";
import {listTypes as list, listAgribalyse as listAgri} from '../../../../redux/calculator'
import {useDispatch, useSelector} from 'react-redux'
import {data, formDataSelect, dataCategoryByType, dataExpandable, chooseTypes} from './conf'

import TableCustom from '../../../../components/Table'

function AgribalysePage() {
  const dispatch = useDispatch()
  const {loadingListTypes, listTypes, listAgribalyse, loadingListAgribalyse} = useSelector((state: { calculator: any; })=> state.calculator)

  const [option, setOption] = useState(chooseTypes(''))

  useEffect(() => {
    dispatch(list());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {
    dispatch(listAgri({type: option, callback: ()=>{}}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option])

  const handleOption = (value:any) => {
    setOption(chooseTypes(value))
  }

  return (
    <TableCustom
    title={"Agribalyse"}
    isAgribalyse
    header={'Agribalyse'}
    isExpandable
    loading={loadingListAgribalyse || loadingListTypes || !listTypes || !listAgribalyse ? true : false}
    data={dataCategoryByType(option, listTypes)}
    pageSize={20}
    isEdit
    isSelect
    columns={data}
    option={option}
    setOption={handleOption}
    columnsExp = {listAgribalyse}
    expandableColumns={dataExpandable}
    selectData={formDataSelect(listTypes)}
    />
  )
}

export default AgribalysePage;

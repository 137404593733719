export const createJSONANC = (data:Array<any>) => {

}

export const data = [
  {
    title: "Valeurs nutritionelles",
    dataIndex: "valeurs nutritionelles",
    key: "valeurs nutritionelles",
    align: 'center'
  },
  {
    title: "Enfant 1-3",
    key: "Enfant 1-3",
    align: 'center',
    children: [
      {
        title: "Min",
        dataIndex: "enfant1-3/Min",
        key: "enfant1-3/Min",
        align: 'center',
        
      },
      {
        title: "Moy",
        dataIndex: "enfant1-3/Moy",
        key: "enfant1-3/Moy",
        align: 'center',
        
      },
      {
        title: "Max",
        dataIndex: "enfant1-3/Max",
        key: "enfant1-3/Max",
        align: 'center',
        
      }
    ]
  },
  {
    title: "Enfant 4-6",
    key: "Enfant 4-6",
    align: 'center',
    children: [
      {
        title: "Min",
        dataIndex: "enfant4-6/Min",
        key: "enfant4-6/Min",
        align: 'center',
        
      },
      {
        title: "Moy",
        dataIndex: "enfant4-6/Moy",
        key: "enfant4-6/Moy",
        align: 'center',
        
      },
      {
        title: "Max",
        dataIndex: "enfant4-6/Max",
        key: "enfant4-6/Max",
        align: 'center',
        
      }
    ]
  },
  {
    title: "Enfant 7-10",
    key: "Enfant 7-10",
    align: 'center',
    children: [
      {
        title: "Min",
        dataIndex: "enfant7-10/Min",
        key: "enfant7-10/Min",
        align: 'center',
        
      },
      {
        title: "Moy",
        dataIndex: "enfant7-10/Moy",
        key: "enfant7-10/Moy",
        align: 'center',
        
      },
      {
        title: "Max",
        dataIndex: "enfant7-10/Max",
        key: "enfant7-10/Max",
        align: 'center',
        
      }
    ]
  },
  {
    title: "Ado 11-14",
    key: "Ado 11-14",
    align: 'center',
    children: [
      {
        title: "Min",
        dataIndex: "ado11-14/Min",
        key: "ado11-14/Min",
        align: 'center',
        
      },
      {
        title: "Moy",
        dataIndex: "ado11-14/Moy",
        key: "ado11-14/Moy",
        align: 'center',
        
      },
      {
        title: "Max",
        dataIndex: "ado11-14/Max",
        key: "ado11-14/Max",
        align: 'center',
        
      }
    ]
  },
  {
    title: "Ado Homme 15-17",
    key: "Ado Homme 15-17",
    align: 'center',
    children: [
      {
        title: "Min",
        dataIndex: "adohomme15-17/Min",
        key: "adohomme15-17/Min",
        align: 'center',
        
      },
      {
        title: "Moy",
        dataIndex: "adohomme15-17/Moy",
        key: "adohomme15-17/Moy",
        align: 'center',
        
      },
      {
        title: "Max",
        dataIndex: "adohomme15-17/Max",
        key: "adohomme15-17/Max",
        align: 'center',
        
      }
    ]
  },
  {
    title: "Ado Femme 15-17",
    key: "Ado Femme 15-17",
    align: 'center',
    children: [
      {
        title: "Min",
        dataIndex: "adofemme15-17/Min",
        key: "adofemme15-17/Min",
        align: 'center',
        
      },
      {
        title: "Moy",
        dataIndex: "adofemme15-17/Moy",
        key: "adofemme15-17/Moy",
        align: 'center',
        
      },
      {
        title: "Max",
        dataIndex: "adofemme15-17/Max",
        key: "adofemme15-17/Max",
        align: 'center',
        
      }
    ]
  },
  {
    title: "Homme > 18",
    key: "Homme > 18",
    align: 'center',
    children: [
      {
        title: "Min",
        dataIndex: "homme>18/Min",
        key: "homme>18/Min",
        align: 'center',
        
      },
      {
        title: "Moy",
        dataIndex: "homme>18/Moy",
        key: "homme>18/Moy",
        align: 'center',
        
      },
      {
        title: "Max",
        dataIndex: "homme>18/Max",
        key: "homme>18/Max",
        align: 'center',
        
      }
    ]
  },
  {
    title: "Femme > 18",
    key: "Femme > 18",
    align: 'center',
    children: [
      {
        title: "Min",
        dataIndex: "femme>18/Min",
        key: "femme>18/Min",
        align: 'center',
        
      },
      {
        title: "Moy",
        dataIndex: "femme>18/Moy",
        key: "femme>18/Moy",
        align: 'center',
        
      },
      {
        title: "Max",
        dataIndex: "femme>18/Max",
        key: "femme>18/Max",
        align: 'center',
        
      }
    ]
  },
];

export const columns = [{}];

import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteFood, fetchFoods } from '../../redux/food'

function FoodModalDeleteForm(props: {
  modalTitle: string;
  isModalVisible: boolean;
  onCancel: any;
  idSelectedDelete: string;
  data: any,
}) {
  const {
    modalTitle,
    isModalVisible,
    onCancel,
    idSelectedDelete,
    data,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [fields, setFields]: any = useState([
    {
      name: ['image'],
      value: '',
    },
    {
      name: ['name'],
      value: '',
    },
    {
      name: ['category'],
      value: '',
    },
    {
      name: ['value'],
      value: '',
    },
    {
      name: ['age'],
      value: '',
    },
    {
      name: ['impacting'],
      value: '',
    },
  ]);
  const [selectedFood, setSelectedFood]: any = useState()

  useEffect(() => {
    const info: any = data?.find((e: any) => e.id === idSelectedDelete);
    setSelectedFood(info)
    /* let ageInfo, impactingInfo
    if (info?.age) ageInfo = '+18 (Adulte)'
    else ageInfo = '-18 (Jeune)'
    if (info?.impacting) impactingInfo = 'Oui'
    else impactingInfo = 'Non' */

    /* setFields([
      {
        name: ['image'],
        value: info?.image,
      },
      {
        name: ['name'],
        value: info?.name,
      },
      {
        name: ['category'],
        value: info?.category,
      },
      {
        name: ['value'],
        value: info?.value,
      },
      {
        name: ['age'],
        value: ageInfo,
      },
      {
        name: ['impacting'],
        value: impactingInfo,
      },
    ]); */
  }, [data, idSelectedDelete])

  useEffect(() => {
    console.log('selectedFood: ', selectedFood)
    let ageInfo, impactingInfo
    if (selectedFood?.age) ageInfo = '+18 (Adulte)'
    else ageInfo = '-18 (Jeune)'
    if (selectedFood?.impacting) impactingInfo = 'Oui'
    else impactingInfo = 'Non'
    setFields([
      {
        name: ['image'],
        value: selectedFood?.image,
      },
      {
        name: ['name'],
        value: selectedFood?.nom,
      },
      {
        name: ['category'],
        value: selectedFood?.categoryName,
      },
      {
        name: ['age'],
        value: ageInfo,
      },
      {
        name: ['impacting'],
        value: impactingInfo,
      },
    ]);
  }, [selectedFood])

  const onSuccess = () => {
    form.resetFields();
    message.success(`L'aliment a bien été supprimé`);
    dispatch(fetchFoods())
    onCancel()
  };

  const handleOk = () => {
    try {
      dispatch(deleteFood({id: idSelectedDelete, callback: () => onSuccess()}));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Réprimer
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} fields={fields}>
          <Form.Item
            label=""
            name="image"
          >
            {selectedFood === null || selectedFood === undefined
              ? <div style={{width: '78px', height: '78px', borderRadius: '39px', backgroundColor: '#D9D9D9'}} />
              : <div style={{width: '78px', height: '78px',  borderRadius: '39px'}} >
                <img src={process.env.REACT_APP_BASE_URL + '/' + selectedFood.image}/>
              </div>
            }  
          </Form.Item>

          <Form.Item
            label="Nom:"
            name="name"
            style={{marginTop: '2rem', fontWeight: 'bold'}}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Catégorie:"
            name="category"
            style={{fontWeight: 'bold'}}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Âge:"
            name="age"
            style={{fontWeight: 'bold'}}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Impactant ou pas:"
            name="impacting"
            style={{fontWeight: 'bold'}}
          >
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default FoodModalDeleteForm;

import React from "react";
import { Input } from "antd";
import { Controller } from "react-hook-form";

export const data = (props: any) => [
  {
    title: "ÂGE",
    key: "age",
    align: "center",
    render: (data: any) => {
      console.log(data);
      return (
        <Controller
          control={props.control}
          name={"age"}
          rules={{ required: "Necesitas incluir una cantidad válida" }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Input
                style={{ textAlign: "center" }}
                value={value || data.age}
                onChange={onChange}
                onBlur={onBlur}
                disabled={
                  !data.id || !props.edit.cell
                    ? true
                    : data.id === props.edit.cell
                    ? false
                    : true
                }
              />
            );
          }}
        />
      );
    },
  },
  {
    title: "1,6",
    key: "1,6",
    align: "center",
    render: (data: any) => (
      <Controller
        control={props.control}
        name={"1,6"}
        rules={{ required: "Necesitas incluir una cantidad válida" }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              style={{ textAlign: "center" }}
              value={value || data["1,6"]}
              onChange={onChange}
              onBlur={onBlur}
              disabled={
                !data.id || !props.edit.cell
                  ? true
                  : data.id === props.edit.cell
                  ? false
                  : true
              }
            />
          );
        }}
      />
    ),
  },
  {
    title: "1,8",
    key: "1,8",
    align: "center",
    render: (data: any) => (
      <Controller
        control={props.control}
        name={"1,8"}
        rules={{ required: "Necesitas incluir una cantidad válida" }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              style={{ textAlign: "center" }}
              value={value || data["1,8"]}
              onChange={onChange}
              onBlur={onBlur}
              disabled={
                !data.id || !props.edit.cell
                  ? true
                  : data.id === props.edit.cell
                  ? false
                  : true
              }
            />
          );
        }}
      />
    ),
  },
  {
    title: 2,
    key: 2,
    align: "center",
    render: (data: any) => (
      <Controller
        control={props.control}
        name={"2"}
        rules={{ required: "Necesitas incluir una cantidad válida" }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              style={{ textAlign: "center" }}
              value={value || data["2"]}
              onChange={onChange}
              onBlur={onBlur}
              disabled={
                !data.id || !props.edit.cell
                  ? true
                  : data.id === props.edit.cell
                  ? false
                  : true
              }
            />
          );
        }}
      />
    ),
  },
];

export const columns = [{}];

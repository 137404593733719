import React, {useEffect, useState} from 'react';
import {
  PageHeader,
  Typography,
  Button,
  Form,
  Input,
  message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {editObjective, fetchObjectives} from '../../../redux/objective'

function Goal () {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { objectives } = useSelector((state: any) => state.objective);

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchObjectives());
  }, [dispatch]);

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Objectif',
    },
  ];

  const [fields, setFields]: any = useState([]);

  useEffect(() => {
    let array: { name: any[]; value: any; key: any; }[] = []
    objectives?.map((e: any) => (
      array.push({
        name: [e.name],
        value: e.value,
        key: e.id,
      })
    ));

    setFields(array);
  }, [objectives])

  const onSuccess = () => {
    form.resetFields();
    message.success('Changement effectué avec succès');
    dispatch(fetchObjectives())
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((fields) => {
        let data: { name: any; value: any; }[] = []
        for (let i = 0; i < Object.keys(fields).length; i++) {
          data.push({
            name: Object.keys(fields)[i],
            value: Object.values(fields)[i]
          })
        }
        dispatch(editObjective({data: data, callback: () => onSuccess()}));
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  }

  const handleCancel = () => {
    let array: { name: any[]; value: any; key: any; }[] = []
    objectives?.map((e: any) => (
      array.push({
        name: [e.name],
        value: e.value,
        key: e.id,
      })
    ));

    setFields(array);
  }

  return (
    <div>
      <PageHeader
        title="Objectif"
        ghost={false}
        breadcrumb={{ routes }}
      />
      <div className="content_container">
        <Form layout="vertical" form={form} fields={fields}>
          <Typography.Title level={4} style={{marginLeft: '65px', marginTop: '20px'}}>Définition de l’objectif</Typography.Title>
          <div>
            <div style={{marginLeft: '135px', marginTop: '40px'}}>
              <Typography.Text className="form_item_small">Air:</Typography.Text>
              <Form.Item
                label=""
                name="air1"
                key="air1"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
              <Form.Item
                label=""
                name="air2"
                key="air2"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
              <Form.Item
                label=""
                name="air3"
                key="air3"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
              <Form.Item
                label=""
                name="air4"
                key="air4"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
            </div>
            <div style={{marginLeft: '129px'}}>
              <Typography.Text className="form_item_small">Eau:</Typography.Text>
              <Form.Item
                label=""
                name="water1"
                key="water1"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
              <Form.Item
                label=""
                name="water2"
                key="water2"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
              <Form.Item
                label=""
                name="water3"
                key="water3"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
              <Form.Item
                label=""
                name="water4"
                key="water4"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
            </div>
            <div style={{marginLeft: '126px'}}>
              <Typography.Text className="form_item_small">Sols:</Typography.Text>
              <Form.Item
                label=""
                name="soil1"
                key="soil1"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
              <Form.Item
                label=""
                name="soil2"
                key="soil2"
                className="form_item_small"
              >
                <Input style={{width: '72px'}} />
              </Form.Item>
            </div>
          </div>
          <Typography.Title level={4} style={{marginLeft: '65px', marginTop: '20px'}}>Définition de la note de l'ambassadeur</Typography.Title>
          <div style={{marginLeft: '126px', marginTop: '40px'}}>
            <Typography.Text className="form_item_small">Personne invitée 1:</Typography.Text>
            <Form.Item
              label=""
              name="ambassadorName1"
              key="ambassadorName1"
              className="form_item_small"
            >
              <Input style={{width: '120px'}} />
            </Form.Item>
            <Typography.Text className="form_item_small">Score unique:</Typography.Text>
            <Form.Item
              label=""
              name="ambassadorScore1"
              key="ambassadorScore1"
              className="form_item_small"
            >
              <Input style={{width: '120px'}} />
            </Form.Item>
          </div>
          <div style={{marginLeft: '126px'}}>
            <Typography.Text className="form_item_small">Personne invitée 2:</Typography.Text>
            <Form.Item
              label=""
              name="ambassadorName2"
              key="ambassadorName2"
              className="form_item_small"
            >
              <Input style={{width: '120px'}} />
            </Form.Item>
            <Typography.Text className="form_item_small">Score unique:</Typography.Text>
            <Form.Item
              label=""
              name="ambassadorScore2"
              key="ambassadorScore2"
              className="form_item_small"
            >
              <Input style={{width: '120px'}} />
            </Form.Item>
          </div>
        </Form>
        <div style={{marginLeft: '450px'}}>
          <Button key="submit" type="primary" onClick={() => handleSubmit()}>
            Valider
          </Button>
          <Button key="cancel" style={{marginLeft: '20px'}} onClick={() => handleCancel()}>
            Annuler
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Goal;

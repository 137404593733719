import { combineReducers } from 'redux';

import auth from './auth/reducer';
import admin from './admin/reducer';
import user from './user/reducer';
import admins from './admins/reducer';
import users from './users/reducer';
import publications from './publications/reducer';
import challenges from './challenges/reducer';
import purchases from './purchases/reducer';
import calculatorSlice from './calculator'
import foodSlice from './food';
import objectiveSlice from './objective'
import statisticsSlice from './statistics';

// Use ES6 object literal shorthand syntax to define the object shape
const rootReducer = combineReducers({
  auth,
  admin,
  user,
  admins,
  users,
  publications,
  purchases,
  challenges,
  calculator: calculatorSlice.reducer,
  food: foodSlice.reducer,
  objective: objectiveSlice.reducer,
  statistics: statisticsSlice.reducer
});

export default rootReducer;

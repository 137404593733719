import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteAdmin } from '../../redux/admin/actions'

function AdminModalDeleteForm(props: {
  modalTitle: string;
  isModalVisible: boolean;
  onCancel: any;
  idSelectedEdit: string;
  data: any,
}) {
  const {
    modalTitle,
    isModalVisible,
    onCancel,
    idSelectedEdit,
    data,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [fields, setFields]: any = useState([
    {
      name: ['email'],
      value: '',
    },
    {
      name: ['username'],
      value: '',
    },
  ]);

  useEffect(() => {
    const info: any = data?.find((e: any) => e.id === idSelectedEdit);
    setFields([
      {
        name: ['email'],
        value: info?.email,
      },
      {
        name: ['username'],
        value: info?.username,
      },
    ]);
  }, [data, idSelectedEdit])

  const onSuccess = () => {
    form.resetFields();
    message.success(`L'administrateur a bien été supprimé`);
    onCancel()
  };

  const handleOk = () => {
    try {
      dispatch(deleteAdmin(idSelectedEdit));
      onSuccess()
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Réprimer
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} fields={fields}>
          <Form.Item
            label="Courrier électronique"
            name="email"
          >
            <Input disabled />
          </Form.Item>

          <Form.Item label="Nom" name="username">
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AdminModalDeleteForm;

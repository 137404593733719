import React, { useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { useSelector } from 'react-redux';

function AdminModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  confirmLoading: boolean;
  successMessage?: string;
}) {
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    confirmLoading,
    successMessage,
  } = props;

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { logUpAdminError } = useSelector((state: any) => state.auth);

  const openModal = () => setVisible(true);
  const onCancel = () => setVisible(false);

  const onSuccess = () => {
    setVisible(false);
    form.resetFields();
    message.success(successMessage || 'Succès');
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm(values, onSuccess);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <>
      <Button
        onClick={openModal}
        style={{
          width: '97px',
          height: '40px',
          border: '1px solid #089EB7',
          backgroundColor: 'white',
          borderRadius: '4px',
          color: '#089EB7',
          position: 'absolute',
          right: '1.6%',
        }}>
        + {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        footer={[
          <Button key="back" onClick={onCancel}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={onOk}>
            Créer
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="E-mail"
            name="email"
            validateStatus={logUpAdminError && 'error'}
            rules={[
              { required: true, message: 'Veuillez entrer votre email' },
              { type: 'email', message: 'Entrer une adresse email valide' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mot de passe"
            name="password"
            validateStatus={logUpAdminError && 'error'}
            rules={[
              { required: true, message: `Veuillez entrez votre mot de passe` },
            ]}
          >
            <Input type="password" />
          </Form.Item>

          <Form.Item
            label="Confirmer mot de passe"
            name="repeat"
            validateStatus={logUpAdminError && 'error'}
            rules={[
              { required: true, message: 'Veuillez re-taper votre mot de passe' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
    
                  return Promise.reject(new Error('Les mots de passe doivent correspondre'));
                },
              }),
            ]}
          >
            <Input type="password" />
          </Form.Item>

          <Form.Item
            label="Nom"
            name="name"
            rules={[
              { required: true, message: `Entrez le nom de l'administrateur` }
            ]}
            validateStatus={logUpAdminError && 'error'}
            help={logUpAdminError || null}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AdminModalForm;

import adminConstants from './constants';
import adminServices from './services';

const {SET_LOADING_ADMINS, SET_ADMINS} = adminConstants

const setLoadingAdmins = (isLoadingAdmins: boolean) => ({
  type: SET_LOADING_ADMINS,
  isLoadingAdmins,
});
const setAdmins = (admins: any) => ({ type: SET_ADMINS, admins });

export const fetchAllAdmins = (filters?: any) => {
  return (dispatch: any) => {
    dispatch(setLoadingAdmins(true));
    adminServices.fetchAllAdmins(filters).then((res: any) => {
      dispatch(setLoadingAdmins(false));
      return dispatch(setAdmins(res?.data?.response));
    });
  };
};

const {SET_NEW_ADMIN, NEW_ADMIN_ERROR} = adminConstants

const setNewAdmin = (isNewAdmin: boolean) => ({
  type: SET_NEW_ADMIN,
  isNewAdmin,
});

const setSignUpError = (logUpAdminError: string | null) => ({
  type: NEW_ADMIN_ERROR,
  logUpAdminError,
});

export const newAdmin = (data: any, callback?: any) => {
  return (dispatch: any) => {
    dispatch(setNewAdmin(true));
    adminServices
      .newAdmin(data.email, data.password, data.username)
      .then((res: any) => {
        if (callback) callback();
        dispatch(setNewAdmin(false));
        dispatch(fetchAllAdmins());
      })
      .catch((err) => {
        if (err?.response?.data?.status === 401) dispatch(setSignUpError('Mot de passe incorrect'))
        else if (err?.response?.data?.status === 404) dispatch(setSignUpError('Utilisateur non trouvé'))
        else dispatch(setSignUpError('Une erreur est survenue'));
        dispatch(setNewAdmin(false));
      });
  };
};

const {SET_DELETING_ADMIN} = adminConstants
const setDeletingAdmin = (isDeletingAdmin: boolean) => ({
  type: SET_DELETING_ADMIN,
  isDeletingAdmin,
});

export const deleteAdmin = (id: any) => {
  return (dispatch: any) => {
    dispatch(setDeletingAdmin(true));
    adminServices
      .deleteAdmin(id)
      .then((res: any) => {
        dispatch(setDeletingAdmin(false));
        dispatch(fetchAllAdmins());
      });
  };
};


const {SET_EDITING_ADMIN} = adminConstants
const setEditingAdmin = (isEditingAdmin: boolean) => ({
  type: SET_EDITING_ADMIN,
  isEditingAdmin,
});

export const editAdmin = (id: any, data: any) => {
  return (dispatch: any) => {
    dispatch(setEditingAdmin(true));
    adminServices
      .editAdmin(id, data)
      .then((res: any) => {
        dispatch(setEditingAdmin(false));
        dispatch(fetchAllAdmins());
      });
  };
};
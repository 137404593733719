import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, message, Select, Upload, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFoods } from 'redux/food';
import SkeletonImage from 'antd/lib/skeleton/Image';

function FoodModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  confirmLoading: boolean;
  successMessage?: string;
}) {
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    confirmLoading,
    successMessage,
  } = props;

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { creatingError, created } = useSelector((state: any) => state.food);
  const [modalCat, setModalCat] = useState(false)
  const [category, setCategory]: any = useState([]);
  const [idCate, setIdCate]: any = useState(0)
  const [image, setImage]: any = useState()
  const openModal = () => setVisible(true);
  const onCancel = () => setVisible(false);

  useEffect(() => {
    if (created) {
      setVisible(false);
      form.resetFields();
      message.success(successMessage || 'Succès');
      dispatch(fetchFoods())
    }
  }, [created, form, successMessage, dispatch])

  useEffect(() => {
    getCategory()
  }, [0])


  const getCategory = async () => {
    try {
      let response = await fetch(process.env.REACT_APP_BASE_URL+'/api/calculator/listCate', {
        method: 'GET'
      })
      let res = await response.json()
      if (res.list.length > 0) {
        setCategory(res.list)
      }
    } catch (error) {
      console.log('plan' + error)
    }
  }

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('values: ', values)
        const data = new FormData()
        data.append('imagen', image)
        Object.keys(values).map((key, index) => {
          data.append(key, values[key])
        })
        onConfirm(data);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleImage = async (info: any) => {
    setImage(info)
    return false
  }


  return (
    <>
      <Button
        onClick={openModal}
        style={{
          width: '97px',
          height: '40px',
          border: '1px solid #089EB7',
          backgroundColor: 'white',
          borderRadius: '4px',
          color: '#089EB7',
          position: 'absolute',
          right: '1.6%',
        }}>
        + {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        footer={[
          <Button key="back" onClick={onCancel}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={onOk}>
            Créer
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="image"
          >
            <div>
              <div style={{ width: '78px', height: '78px', borderRadius: '39px', backgroundColor: '#D9D9D9', display: 'inline-block' }} />
              <Upload
                name='image'
                beforeUpload={handleImage}
              >
                <Button
                  key="add"
                  style={{
                    display: 'inline-block',
                    marginLeft: '40px',
                    border: '1px solid #089EB7',
                    backgroundColor: 'white',
                    color: '#089EB7',
                    borderRadius: '4px'
                  }}
                >
                  Ajouter
                </Button>
              </Upload>
            </div>
          </Form.Item>
          {/* <Form.Item
            label=""
            name="image"
            validateStatus={creatingError && 'error'}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            label="Nom:"
            name="nom"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true, message: `Veuillez saisir le nom de l'aliment` },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Example:"
            name="example"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: false },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Color"
            name="color"
            rules={[
              { required: true }
            ]}
            validateStatus={creatingError && 'error'}
            help={creatingError || null}
          >
            <Select optionFilterProp="children">
              <Select.Option key="Rouge" value="Rouge">Rouge</Select.Option>
              <Select.Option key="Jaune" value="Jaune">Jaune</Select.Option>
              <Select.Option key="Bleu" value="Bleu">Bleu</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: false }]}
          >
            <Input.TextArea showCount />
          </Form.Item>

          <Form.Item
            label="Catégorie"
            name="categoryId"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true, message: `Veuillez entrer la catégorie d'aliments` },
            ]}
          >
            <Select>
              {category.map((cat: any, index: any) => {
                return (
                  <Select.Option key={index} value={cat.id}>{cat.name}</Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          {/* <Form.Item
            label="Valeur"
            name="value"
            rules={[
              { required: true, message: `Entrez le valeur de l'aliment` }
            ]}
            validateStatus={creatingError && 'error'}
            help={creatingError || null}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            label="Âge"
            name="adulte"
            rules={[
              { required: true, message: `Veuillez indiquer si vous avez l'âge légal` }
            ]}
            validateStatus={creatingError && 'error'}
            help={creatingError || null}
          >
            <Select optionFilterProp="children">
              <Select.Option key="adulte" value="adulte">+18 (Adulte)</Select.Option>
              <Select.Option key="jeune" value="jeune">-18 (Jeune)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Impactant ou pas"
            name="impactant"
            rules={[
              { required: true, message: `Veuillez indiquer si cela a un impact sur l'environnementl` }
            ]}
            validateStatus={creatingError && 'error'}
            help={creatingError || null}
          >
            <Select optionFilterProp="children">
              <Select.Option key="oui" value="oui">Oui</Select.Option>
              <Select.Option key="non" value="non">Non</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Portion:"
            name="portion"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="nrj_kcal:"
            name="nrj_kcal"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="eau_g:"
            name="eau_g"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="sel_g:"
            name="sel_g"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="sodium_mg:"
            name="sodium_mg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="calcium_mg:"
            name="calcium_mg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="fer_mg:"
            name="fer_mg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="zinc_mg:"
            name="zinc_mg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="iode_mg:"
            name="iode_mg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="proteines_g:"
            name="proteines_g"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="sucres_g:"
            name="sucres_g"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="sucre_g_sans_lactose:"
            name="sucre_g_sans_lactose"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ags_g:"
            name="ags_g"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="vitamine_a_mg:"
            name="vitamine_a_mg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="vitamine_c_mg:"
            name="vitamine_c_mg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="vitamine_b12_mcg:"
            name="vitamine_b12_mcg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="vitamine_b9_mcg:"
            name="vitamine_b9_mcg"
            validateStatus={creatingError && 'error'}
            rules={[
              { required: true },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default FoodModalForm;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, message, Select } from 'antd';
import styles from './styles.module.scss';

function CategoriesModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  successMessage?: string;
  initialValues?: any;
  categories?: any[];
  itemId?: string;
}) {
  const [imageFile, setImageFile] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const {
    modalTitle,
    openButtonText,
    onConfirm,
    successMessage,
    itemId,
  } = props;
  const { initialValues, categories } = props;

  const openModal = () => setVisible(true);
  const onCancel = () => setVisible(false);
  const onSuccess = () => {
    setVisible(false);
    form.resetFields();
    message.success(successMessage || 'Exito');
  };
  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        // Generate form data
        var formData = new FormData();
        for (var key in values) {
          if (values[key]) {
            formData.append(key, values[key]);
          }
        }

        // Run function
        if (itemId) {
          onConfirm(itemId, formData, onSuccess);
          setVisible(false);
        } else {
          onConfirm(formData, onSuccess);
          setVisible(false);
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  const onImageChange = (e: any) => {
    setImageFile(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };


  return (
    <>
      <Button type="primary" onClick={openModal}>
        {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Form layout="vertical" form={form} initialValues={initialValues}>
          {/* NAME */}
          <Form.Item
            label="Nombre"
            name="name"
            rules={[
              { required: true, message: 'Por favor introduce un nombre' },
            ]}
          >
            <Input placeholder="Nombre" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default CategoriesModalForm;

import React, { useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { Layout, Menu, Row, message, Button } from "antd";
import {
  BarChartOutlined,
  TeamOutlined,
  TrophyOutlined,
  MailOutlined,
} from "@ant-design/icons";

import Administrator from "./administrator";
import FoodManagement from "./foodManagement";
import Goal from "./goal";

import styles from "./styles.module.scss";
import logo from "../../assets/img/logo-horizontal-waterfamily.png";
import logoutIcon from "../../assets/icon/logout.png";

import calculator from "../../assets/icon/calculator.svg";
import restaurant from "../../assets/icon/restaurant.svg";
import Icono from "../../components/MenuItemImg";

import Users from "./users/";
import Admins from "./admins/";

import PublicationsWater from "./publications/water";
import PublicationsLight from "./publications/light";
import PublicationsGas from "./publications/gas";

import ChallengeWater from "./challenge/water";
import ChallengeGas from "./challenge/gas";
import ChallengeLight from "./challenge/light";
import ChallengeImprovement from "./challenge/improvement";

import Purchase from "./purchase";

import { useDispatch, useSelector } from "react-redux";
import { logoutUser, fetchUser } from "../../redux/user/actions";

/* CALCULATOR */

import EnergyPage from "./calculator/energy";
import ImpactPage from "./calculator/impact";
import AncPage from "./calculator/anc";
import QuestionsPage from "./calculator/questions";
import AgribalysePage from "./calculator/agribalyse";
import CalnutPage from "./calculator/calnut";
import Statistiques from "./statistics/index"
import Categories from "./categories"

const { Header, Sider, Content } = Layout;

function Dashboard() {
  const { SubMenu } = Menu;

  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    history.push("/login");
    message.success("Vous êtes déconnecté");
  };

  const { user, checkingSession, userInfo } = useSelector(
    (state: any) => state.user
  );

  useEffect(() => {
    if (checkingSession === false) {
      if (user) {
        dispatch(fetchUser(user));
      }
    }
  }, [checkingSession, user, dispatch]);

  const logout = () => {
    dispatch(logoutUser(onLogout));
  };

  return (
    <Layout>
      <Header className={styles.header}>
        <Row justify="end">
          <img src={logo} alt="Logo" className={styles.logo} />
          <div className={styles.grid_container}>
            <div className={styles.grid_item}>{userInfo?.email}</div>
            <div className={styles.grid_item}>
              <Button
                style={{
                  color: "#089EB7",
                  border: "1px solid #089EB7",
                  borderRadius: "4px",
                  backgroundColor: "transparent",
                }}
                onClick={logout}
              >
                <span className={styles.icon}>
                  <img src={logoutIcon} alt="" />
                </span>
                Fermer la session
              </Button>
            </div>
          </div>
        </Row>
      </Header>
      <Layout>
        <Sider breakpoint="lg">
          <Menu
            theme="light"
            style={{ minWidth: "200px", minHeight: "929px" }}
            mode="inline"
            defaultSelectedKeys={[window.location.pathname.replace("/", "")]}
          >
            <Menu.Item key="administrator" icon={<TeamOutlined />}>
              <Link className={styles.linkItem} to="/administrator">
                Administrateur
              </Link>
            </Menu.Item>

            <SubMenu
              key="calculator"
              title="Calculateur"
              icon={<Icono img={calculator} />}
            >
              <Menu.Item key="1">
                <Link to="/calculator/agribalyse">Agribalyse</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/calculator/anc">ANC</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/calculator/calnut">Calnut</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/calculator/energy">Energie</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/calculator/impact">Impact</Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to="/calculator/questions">Les questions</Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="food-management" icon={<Icono img={restaurant} />}>
              <Link to="/food-management">Gestion des aliments</Link>
            </Menu.Item>

            <Menu.Item key="categories" icon={<Icono img={restaurant} />}>
              <Link to="/categories">Categories</Link>
            </Menu.Item>

            <Menu.Item key="goal" icon={<TrophyOutlined />}>
              <Link to="/goal">Objectif</Link>
            </Menu.Item>

            <Menu.Item key="statistiques" icon={<BarChartOutlined />}>
              <Link to="/statistiques">Statistiques</Link>
            </Menu.Item>

            <Menu.Item key="newsletter" icon={<MailOutlined />}>
              <Link to="newsletter">Bulletin</Link>
            </Menu.Item>

            {/* <SubMenu
              key="estadisticas"
              title="Estadísticas"
              icon={<Icono img={estadisticasIcon} />}
            >
              <Menu.Item key="1">
                <Link to="/estadisticas/usuarios">Usuarios</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/estadisticas/ventas">Ventas</Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="usuarios" icon={<Icono img={usuariosIcon} />}>
              <Link to="/usuarios">Usuarios</Link>
            </Menu.Item>

            <Menu.Item key="comentarios" icon={<Icono img={comentariosIcon} />}>
              <Link to="/comentarios">Comentarios</Link>
            </Menu.Item>

            <SubMenu key="retos" icon={<Icono img={retosIcon} />} title="Retos">
              <Menu.Item key="3">
                <Link to="/retos/agua">Agua</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/retos/luz">Luz</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/retos/gas">Gas</Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to="/retos/mejora">Mejora</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="publicaciones"
              icon={<Icono img={publicacionesIcon} />}
              title="Publicaciones"
            >
              <Menu.Item key="7">
                <Link to="/publicaciones/agua">Agua</Link>
              </Menu.Item>
              <Menu.Item key="8">
                <Link to="/publicaciones/luz">Luz</Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/publicaciones/gas">Gas</Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="compras" icon={<Icono img={shoppingIcon} />}>
              <Link to="/compras">Compras</Link>
            </Menu.Item>

            <Menu.Item
              key="administradores"
              icon={<Icono img={administradoresIcon} />}
            >
              <Link to="/administradores">Administradores</Link>
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Content className={styles.content}>
          <Switch>
            <Route path="/administrator" component={Administrator} exact />
            <Route path="/food-management" component={FoodManagement} />
            <Route path="/goal" component={Goal} />
            <Route path="/calculator/energy" component={EnergyPage} />
            <Route path="/calculator/impact" component={ImpactPage} />
            <Route path="/calculator/anc" component={AncPage} />
            <Route path="/calculator/questions" component={QuestionsPage} />
            <Route path="/calculator/agribalyse" component={AgribalysePage} />
            <Route path="/calculator/calnut" component={CalnutPage} />
            <Route path="/statistiques" component={Statistiques} />
            <Route path="/categories" component={Categories} />
            {/*
            <Route path="/statistics" component={Statistics} />
            <Route path="/newsletter" component={Newsletter} />
            <Route path="/estadisticas/usuarios" component={Admins} />
            <Route path="/estadisticas/ventas" component={Admins} />
            <Route path="/usuarios" component={Users} />
            <Route path="/comentarios" component={Admins} />
            <Route path="/retos/agua" component={ChallengeWater} />
            <Route path="/retos/luz" component={ChallengeLight} />
            <Route path="/retos/gas" component={ChallengeGas} />
            <Route path="/retos/mejora" component={ChallengeImprovement} />
            <Route path="/publicaciones/agua" component={PublicationsWater} />
            <Route path="/publicaciones/luz" component={PublicationsLight} />
            <Route path="/publicaciones/gas" component={PublicationsGas} />
            <Route path="/compras" component={Purchase} />
            <Route path="/administradores" component={Admins} /> */}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;

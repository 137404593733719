import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, PageHeader, Table, Empty } from "antd";
import Select from "../../components/Select";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";

const TableCustom = (props: {
  title: string;
  header: string;
  columns: Array<any>;
  isExpandable?: boolean;
  expandableColumns?: Array<any>;
  columnsExp?: Array<any>;
  data: Array<any>;
  loading: boolean;
  selectData?: Array<any>;
  bordered?: boolean;
  pageSize?: number;
  isEdit?: boolean;
  isDelete?: boolean;
  isAgribalyse?: boolean;
  isEditExpandable?: boolean;
  isDeleteExpandable?: boolean;
  isSelect?: boolean;
  handleEdit?: (data: any) => void;
  handleDelete?: (data: any) => void;
  option?: any;
  edit?: any;
  setOption?: (data: string) => void;
  handleSubmit?: (data: any) => any
}) => {
  const {
    title,
    header,
    edit,
    data,
    columns,
    columnsExp,
    isAgribalyse,
    expandableColumns,
    handleSubmit,
    isExpandable,
    selectData,
    loading,
    pageSize,
    bordered,
    isEdit,
    isEditExpandable,
    isDeleteExpandable,
    isSelect,
    option,
    setOption,
    handleEdit,
    isDelete,
    handleDelete,
  } = props;

  const [select, setSelect] = useState(false);

  const routes = [
    {
      path: "index",
      breadcrumbName: "Calculateur",
    },
    {
      path: "first",
      breadcrumbName: header,
    },
  ];

  const expandedRowRender = (data: any) => {
    const columnsExpandable = expandableColumns || [];
    const columns = [
      ...columnsExpandable,
      isEditExpandable
        ? {
          title: (
            <EditOutlined style={{ fontSize: "16px", color: "#089EB7" }} />
          ),
          align: "center",
          render: (value: any) => (
            <div onClick={() => handleEdit && handleEdit(data)}>
              <EditOutlined style={{ fontSize: "16px", color: "#089EB7" }} />
            </div>
          ),
        }
        : {},
      isDeleteExpandable
        ? {
          title: (
            <DeleteOutlined style={{ fontSize: "16px", color: "#089EB7" }} />
          ),
          dataIndex: "id",
          key: "delete",
          align: "center",
          render: (text: any, record: any) => (
            <div onClick={() => handleDelete && handleDelete(text)}>
              <DeleteOutlined
                style={{ fontSize: "16px", color: "#089EB7" }}
              />
            </div>
          ),
        }
        : {},
    ];
    return (
      <Table
        columns={columns}
        dataSource={isAgribalyse ? columnsExp?.filter(ind => ind.id === data.id)[0].agribalyse : columnsExp?.filter(ind => ind.id === data.id)[0].calnut}
        pagination={false}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Pas de données.' /> }}
      />
    );
  };

  return (
    <>
      <PageHeader
        title={title}
        ghost={false}
        breadcrumb={{ routes }}
        style={{ backgroundColor: "#E5E5E5" }}
      />
      <div style={{ paddingLeft: "25px" }}>
        {isSelect ? (
          <Select
            option={option}
            select={select}
            setSelect={setSelect}
            setOption={setOption}
            data={selectData || []}
          />
        ) : null}
      </div>
      <div className="content_container">
        <Table
          pagination={{ pageSize: pageSize ? pageSize : undefined }}
          dataSource={data}
          loading={loading}
          bordered={bordered}
          rowKey="id"
          scroll={{ x: 400 }}
          expandable={
            isExpandable
              ? {
                expandedRowRender,
              }
              : undefined
          }
          columns={[
            ...columns,
            isEdit
              ? {
                title: (
                  <EditOutlined
                    style={{ fontSize: "16px", color: "#089EB7" }}
                  />
                ),
                align: "center",
                render: (data) => {
                  return (
                    <div onClick={() => handleEdit && handleEdit(data)}>
                      {edit?.cell !== data.id ? <EditOutlined
                        style={{ fontSize: "16px", color: "#089EB7" }}
                      /> : <div><Button style={{ color: 'black', border: '1px solid #D9D9D9', borderRadius: '4px' }}>Annuler</Button><Button onClick={handleSubmit && handleSubmit()} style={{ borderRadius: '4px', background: '#089EB7', color: 'white' }} title={'Valider'}>Valider</Button></div>}
                    </div>
                  )
                },
              }
              : {},
            isDelete
              ? {
                title: (
                  <DeleteOutlined
                    style={{ fontSize: "16px", color: "#089EB7" }}
                  />
                ),
                dataIndex: "id",
                key: "delete",
                align: "center",
                render: (text: any, record: any) => (
                  <div onClick={() => handleDelete && handleDelete(text)}>
                    <DeleteOutlined
                      style={{ fontSize: "16px", color: "#089EB7" }}
                    />
                  </div>
                ),
              }
              : {},
          ]}
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Pas de données.' /> }}
        />
      </div>
    </>
  );
};

TableCustom.propType = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  headerTitle: PropTypes.object,
  pageSize: PropTypes.number,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default TableCustom;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableCustom from "../../../../components/Table";
import { listImpact as list} from "../../../../redux/calculator";
import { data } from "./conf";

function EnergyPage() {
  const dispatch = useDispatch();
  const { loadingListImpact, listImpact } = useSelector(
    (state: any) => state.calculator
  );

  useEffect(() => {
    dispatch(list({ callback: () => {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TableCustom
        title={'Empreinte global'}
        header={'Impact'}
        loading={loadingListImpact || !listImpact ? true : false}
        data={listImpact}
        columns={data}
      />
    </>
  );
}

export default EnergyPage;

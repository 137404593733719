import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { PageHeader, Row, Col, Select } from 'antd'
import { Bar } from 'react-chartjs-2';
import { fetchStatisticsBySex } from "../../../redux/statistics/index";
import moment from "moment";

function StatistiquesBySex() {
    const { Option } = Select
    const [dates, setDates] = useState({
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().endOf('year').format('YYYY-MM-DD')
    })
    const { statisticsBySex, fetchStatisticsLoading } = useSelector((state: { statistics: any; }) => state.statistics)
    const dispatch = useDispatch()
    const [mens, setMens]: any = useState({})
    const [girls, setGirls]: any = useState({})
    const [years, setYears]: any = useState([])

    useEffect(() => {
        dispatch(fetchStatisticsBySex(dates))
        let currentYear = moment().year()
        let temp = []
        for (let index = currentYear - 3; index <= currentYear + 3; index++) {
            temp.push(index)
        }
        setYears(temp)
    }, [])
    useEffect(() => {
        if (statisticsBySex != null) {
            setMens(statisticsBySex.mens)
            setGirls(statisticsBySex.girls)
        }
    }, [fetchStatisticsLoading])
    const routes = [
        {
            path: "index",
            breadcrumbName: "Statistiques",
        },
        {
            path: "first",
            breadcrumbName: "Statistics",
        },
    ];
    const data = {
        labels: ['E', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
        datasets: [
            {
                label: 'Homme',
                data: [mens['1'], mens['2'], mens['3'], mens['4'], mens['5'], mens['6'], mens['7'], mens['8'], mens['9'], mens['10'], mens['11'], mens['12']],
                backgroundColor: 'rgba(26, 51, 86, 1)',
            },
            {
                label: 'Femme',
                data: [girls['1'], girls['2'], girls['3'], girls['4'], girls['5'], girls['6'], girls['7'], girls['8'], girls['9'], girls['10'], girls['11'], girls['12']],
                backgroundColor: 'rgba(8, 158, 183, 1)',
            },
        ],
    };

    const options: any = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    const handleDates = (value: any) => {
        setDates({
            startDate: `${value}-01-01`,
            endDate: `${value}-12-31`
        })
    }

    useEffect(() => {
        dispatch(fetchStatisticsBySex(dates))
    }, [dates])

    return (
        <>
            <div>
                <Row style={{ backgroundColor: 'white', padding: '3% 0% 3% 10%'}}>
                    <Col sm={24}>
                        <h2>Sexe</h2>
                    </Col>
                    <Col sm={24}>
                        <Select defaultValue={moment().year()} onChange={handleDates}>
                            {years.map((e: any) => {
                                return (
                                    <Option value={e}>{e}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <div style={{ backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Bar style={{ height: '100%', width: '100%' }} data={data} options={options} />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default StatistiquesBySex;


export const chooseTypes = (name: string) => {
  let option = 0
  switch (name) {
    case "":
      option = 1
      break;
    case "Petit déjeuner":
      option = 1
      break;
    case "Entrée":
      option = 2
      break;
    case "Plat":
      option = 3
      break;
    case "Dessert":
      option = 4
      break;
    case "Gouter":
      option = 5
      break;
    case "Apero":
      option = 6
      break;
    case "Boissons de la journée":
      option = 7
      break;
    default:
      break;
  }
  return option
};

export const formDataSelect = (listTypes: Array<any>) => {
  const array: any[] = [];
  listTypes.map((ind) => {
    return array.push(ind.name);
  });
  return array;
};

export const dataCategoryByType = (type: any, listTypes: Array<any>) => {
  return listTypes.length > 0
    ? listTypes.filter((ind) => ind.id === type)[0].categories
    : [];
};

export const data = [
  {
    title: "Nom",
    dataIndex: "name",
    key: "name",
    align: "left",
  },
];

export const dataExpandable = [
  {
    title: "Montant",
    dataIndex: "portion",
    key: "portion",
    align: "left",
  },
  {
    title: "Producte",
    dataIndex: "nom",
    key: "nom",
    align: "left",
  },
  {
    title: "Exemple",
    dataIndex: "example",
    key: "example",
    align: "left",
  },
  {
    title: "Score unique EF (mPt/kg de produit)",
    dataIndex: "score_unique_EF",
    key: "score_unique_EF",
    align: "left",
  },
  {
    title: "Changement climatique (kg CO2 eq/kg de produit)",
    dataIndex: "changement_climatique",
    key: "changement_climatique",
    align: "left",
  },
  {
    title:
      "Appauvrissement de la couche d'ozone (E-06 kg CVC11 eq/kg de produit)",
    dataIndex: "changement_climatique",
    key: "changement_climatique",
    align: "left",
  },
  {
    title: "Rayonnements ionisants (kBq U-235 eq/kg de produit)",
    dataIndex: "rayonnements_ionisants",
    key: "rayonnements_ionisants",
    align: "left",
  },
  {
    title: "Formation photochimique dozone (E-03 kg NMVOC eq/kg de produit)",
    dataIndex: "formation_photochimique",
    key: "formation_photochimique",
    align: "left",
  },
  {
    title: "Particules (E-06 disease inc./kg de produit)",
    dataIndex: "particules",
    key: "particules",
    align: "left",
  },
  {
    title: "Acidification terrestre et eaux douces (mol H+ eq/kg de produit)",
    dataIndex: "acidification_terrestre_et_eaux_douces",
    key: "acidification_terrestre_et_eaux_douces",
    align: "left",
  },
  {
    title: "Eutrophisation terreste (mol N eq/kg de produit)",
    dataIndex: "eutrophisation_terreste",
    key: "eutrophisation_terreste",
    align: "left",
  },
  {
    title: "Eutrophisation eaux douces (E-03 kg P eq/kg de produit)",
    dataIndex: "eutrophisation_eaux_douces",
    key: "eutrophisation_eaux_douces",
    align: "left",
  },
  {
    title: "Eutrophisation marine (E-03 kg N eq/kg de produit)",
    dataIndex: "eutrophisation_marine",
    key: "eutrophisation_marine",
    align: "left",
  },
  {
    title: "MaxiUtilisation du sol (Pt/kg de produit)mum",
    dataIndex: "utilisation_du_sol",
    key: "utilisation_du_sol",
    align: "left",
  },
  {
    title:
      "Écotoxicité pour écosystèmes aquatiques deau douce (CTUe/kg de produit)",
    dataIndex: "ecotoxicite_pour_ecosystemes_aquatiques",
    key: "ecotoxicite_pour_ecosystemes_aquatiques",
    align: "left",
  },
  {
    title: "Épuisement des ressources eau (m3 depriv./kg de produit)",
    dataIndex: "epuisement_des_ressources_eau",
    key: "epuisement_des_ressources_eau",
    align: "left",
  },
  {
    title: "Épuisement des ressources energetiques (MJ/kg de produit)",
    dataIndex: "epuisement_des_ressources_energetiques",
    key: "epuisement_des_ressources_energetiques",
    align: "left",
  },
  {
    title: "Épuisement des ressources minéraux (E-06 kg Sb eq/kg de produit)",
    dataIndex: "epuisement_des_essources_minéraux",
    key: "epuisement_des_essources_minéraux",
    align: "left",
  },
];

import api from '../../helpers/api';

const foodServices = {
  fetchAll: () => {
    return api.get('/calculator/listProducts');
  },
  create: (
    /* image: any, 
    example: any,
    color: any,
    message: any,
    nom: any, 
    categoryId: any, 
    // value: number, 
    adulte: boolean, 
    impactant: boolean,
    portion: number,
    nrj_kcal: number,
    eau_g: number,
    sel_g: number,
    sodium_mg: number,
    calcium_mg: number,
    fer_mg: number,
    zinc_mg: number,
    iode_mg: number,
    proteines_g: number,
    sucres_g: number,
    sucre_g_sans_lactose: number,
    ags_g: number,
    vitamine_a_mg: number,
    vitamine_c_mg: number,
    vitamine_b12_mcg: number,
    vitamine_b9_mcg: number, */
    data: any
  ) => {
    return api.post('food/create', data /* {
      image,
      example,
      color,
      message,
      nom,
      categoryId,
      // value,
      adulte,
      impactant,
      portion,
      nrj_kcal,
      eau_g,
      sel_g,
      sodium_mg,
      calcium_mg,
      fer_mg,
      zinc_mg,
      iode_mg,
      proteines_g,
      sucres_g,
      sucre_g_sans_lactose,
      ags_g,
      vitamine_a_mg,
      vitamine_c_mg,
      vitamine_b12_mcg,
      vitamine_b9_mcg
    } */);
  },
  delete: (id: any) => {
    return api.delete('food/delete/' + id);
  },
  edit: (id: any, data: any) => {
    return api.put('/food/edit/' + id, data);
  },
};

export default foodServices;

import React, { useState, useEffect } from 'react';
import { PageHeader, Table, Button, Popconfirm, Space, message, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { editCategory, createCategory, deleteCategory } from "../../../redux/categories";
// import {
//   createCategory,
//   deleteCategory,
//   fetchCategories,
//   editCategory,
// } from '../../../redux/categories/actions';
import CategoriesModalForm from '../../../components/CategoriesModalForm';
import Avatar from 'antd/lib/avatar/avatar';

function Categories() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([])
  // const [filters] = useState({
  //   nested: true,
  // });
  // const { data } = useSelector((state: any) => state.categories);

  // On first load, fetch data
  // useEffect(() => {
  // dispatch(fetchCategories(filters));
  // }, [dispatch, filters]);

  useEffect(() => {
    getCategory()
  }, [0])

  const getCategory = async () => {
    try {
      let response = await fetch(process.env.REACT_APP_BASE_URL+'/api/calculator/listCate', {
        method: 'GET'
      })
      let res = await response.json()
      if (res.list.length > 0) {
        setCategories(res.list)
      }
    } catch (error) {
      console.log('plan' + error)
    }
  }

  // const createItem = (data: { name: string; image: string }, callback: any) => {
  //   dispatch(
  //     createCategory(data, () => {
  //       callback();
  //       dispatch(fetchCategories(filters));
  //     })
  //   );
  // };

  // const editItem = (
  //   id: string,
  //   data: { name: string; image: string },
  //   callback: any
  // ) => {
  //   dispatch(
  //     editCategory(id, data, () => {
  //       callback();
  //       dispatch(fetchCategories(filters));
  //     })
  //   );
  // };

  // const deleteItem = (id: string) => {
  //   dispatch(
  //     deleteCategory(id, () => {
  //       message.success('Categoria borrada con exito');
  //     })
  //   );
  // };

  const onSuccess = () => {
    getCategory()
    message.success('OK');
  }

  const createItem = async (data: any) => {
    let dataValues: any = {
      name: data.get('name')
    }
    await dispatch(createCategory(dataValues))
    getCategory()
  }

  const editItem = async (id: any, data: any) => {
    let dataValues: any = {
      id: id,
      data: { name: data.get('name') }
    }
    await dispatch(editCategory(dataValues))
    getCategory()
  }

  const deleteItem = (id: any) => {
    dispatch(deleteCategory({ id: id, callback: () => onSuccess() }));
  }

  return (
    <div>
      <PageHeader
        title="Categories"
        ghost={false}
        extra={[
          // <CategoriesModalForm
          //   openButtonText="Añadir"
          //   modalTitle="Crear categoria"
          //   successMessage="Categoria creada correctamente"
          //   onConfirm={createItem}
          //   categories={data}
          // />,
        ]}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem', marginRight: '2rem' }}>
        <CategoriesModalForm
          openButtonText="Añadir"
          modalTitle="Crear categoria"
          successMessage="Categoria creada correctamente"
          onConfirm={createItem}
          categories={categories}
        />
      </div>
      <div className="content_container">
        <Table
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Pas de données.' /> }}
          dataSource={categories}
          rowKey="id"
          columns={[
            {
              title: 'Id',
              dataIndex: 'id',
              key: 'id',
              defaultSortOrder: 'descend',
              sorter: (a: any, b: any) => a.id - b.id,

            },
            {
              title: 'Nombre',
              dataIndex: 'name',
              key: 'name',
              sorter: (a: any, b: any) => a.name.length - b.name.length,
            },
            {
              title: 'Acciones',
              key: 'actions',
              render: (row) => (
                <Space>
                  <CategoriesModalForm
                    openButtonText="Editar"
                    modalTitle="Editar categoria"
                    successMessage="Categoria editada correctamente"
                    itemId={row.id}
                    onConfirm={editItem}
                    initialValues={row}
                    categories={categories}
                  />
                  <Popconfirm
                    title="Confirmar"
                    okText="Si"
                    cancelText="No"
                    onConfirm={() => deleteItem(row.id)}
                  >
                    <Button danger>Borrar</Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Categories;

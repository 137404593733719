import api from '../../helpers/api';

const adminServices = {
  fetchAllAdmins: (filters: any) => {
    return api.get('/admins/listadmins', { params: { ...filters } });
  },
  newAdmin: (email: any, password: any, username: any) => {
    return api.post('admins/signup/admin/', {
      email,
      password,
      username,
    });
  },
  deleteAdmin: (id: any) => {
    return api.delete('admins/delete/admin/' + id);
  },
  editAdmin: (id: any, data: any) => {
    return api.put('/admins/edit/admin/' + id, data);
  },
};

export default adminServices;
